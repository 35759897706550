var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { attrs: { "aria-label": _vm.$tr("optionsLabel") } },
    [
      _c(
        "UiToolbar",
        {
          staticClass: "toolbar",
          staticStyle: { "z-index": "8" },
          style: _vm.contentSpecificStyles,
          scopedSlots: _vm._u([
            {
              key: "icon",
              fn: function () {
                return [
                  _c("KIconButton", {
                    attrs: {
                      icon: "back",
                      "data-test": "backButton",
                      tooltip: _vm.coreString("goBackAction"),
                      ariaLabel: _vm.coreString("goBackAction"),
                    },
                    on: { click: _vm.onBackButtonClick },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "actions",
              fn: function () {
                return [
                  _c("Transition", { attrs: { name: "downloading-loader" } }, [
                    _c(
                      "span",
                      { ref: "downloadingLoader" },
                      [
                        _c("KCircularLoader", {
                          style: { margin: "10px 4px 0px 4px" },
                          attrs: {
                            shouldShow: _vm.isDownloading,
                            minVisibleTime: 3000,
                            "data-test": "downloadingLoader",
                            size: 24,
                          },
                        }),
                        _c(
                          "KTooltip",
                          {
                            attrs: {
                              reference: "downloadingLoader",
                              refs: _vm.$refs,
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.downloadingLoaderTooltip) +
                                "\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm.isQuiz && !_vm.showingReportState
                    ? _c("KIconButton", {
                        ref: "timerButton",
                        attrs: {
                          "data-test": "timerButton",
                          icon: "timer",
                          tooltip: _vm.coreString("timeSpentLabel"),
                          ariaLabel: _vm.coreString("timeSpentLabel"),
                        },
                        on: { click: _vm.toggleTimer },
                      })
                    : _vm._e(),
                  _c("CoreMenu", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isTimerOpen,
                        expression: "isTimerOpen",
                      },
                    ],
                    ref: "timer",
                    staticClass: "menu",
                    style: {
                      left: _vm.isRtl ? "16px" : "auto",
                      right: _vm.isRtl ? "auto" : "16px",
                    },
                    attrs: {
                      raised: true,
                      isOpen: _vm.isTimerOpen,
                      containFocus: true,
                    },
                    on: { close: _vm.closeTimer },
                    scopedSlots: _vm._u([
                      {
                        key: "options",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "timer-display" },
                              [
                                _c("div", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.coreString("timeSpentLabel"))
                                    ),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { style: { paddingBottom: "8px" } },
                                  [
                                    _c("TimeDuration", {
                                      attrs: { seconds: _vm.timeSpent },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.duration
                                  ? _c("div", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.learnString("suggestedTime")
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm.duration
                                  ? _c("SuggestedTime", {
                                      attrs: { seconds: _vm.duration },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm.deviceId
                    ? _c("DeviceConnectionStatus", {
                        attrs: { deviceId: _vm.deviceId },
                      })
                    : _vm._e(),
                  _c(
                    "TransitionGroup",
                    { attrs: { name: "bar-actions" } },
                    _vm._l(_vm.barActions, function (action) {
                      return _c("KIconButton", {
                        key: action.id,
                        staticClass: "bar-actions-item",
                        class:
                          action.id === "next-steps" && _vm.nextStepsAnimate
                            ? "bounce"
                            : "",
                        attrs: {
                          "data-test": "bar_" + action.dataTest,
                          icon: action.icon,
                          color: action.iconColor,
                          tooltip: action.label,
                          ariaLabel: action.label,
                          disabled: action.disabled,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onActionClick(action.event)
                          },
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    { staticClass: "menu-wrapper" },
                    [
                      _vm.menuActions.length
                        ? _c("KIconButton", {
                            ref: "moreOptionsButton",
                            attrs: {
                              "data-test": "moreOptionsButton",
                              icon: "optionsHorizontal",
                              tooltip: _vm.$tr("moreOptions"),
                              ariaLabel: _vm.$tr("moreOptions"),
                            },
                            on: { click: _vm.toggleMenu },
                          })
                        : _vm._e(),
                      _c("CoreMenu", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.isMenuOpen,
                            expression: "isMenuOpen",
                          },
                        ],
                        ref: "menu",
                        staticClass: "menu",
                        style: {
                          left: _vm.isRtl ? "16px" : "auto",
                          right: _vm.isRtl ? "auto" : "16px",
                        },
                        attrs: {
                          raised: true,
                          isOpen: _vm.isMenuOpen,
                          containFocus: true,
                        },
                        on: {
                          close: _vm.closeMenu,
                          shouldFocusFirstEl: function ($event) {
                            return _vm.findFirstEl()
                          },
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "options",
                            fn: function () {
                              return _vm._l(_vm.menuActions, function (action) {
                                return _c(
                                  "CoreMenuOption",
                                  {
                                    key: action.id,
                                    style: { cursor: "pointer" },
                                    attrs: {
                                      "data-test": "menu_" + action.dataTest,
                                      disabled: action.disabled,
                                      icon: action.icon,
                                    },
                                    on: {
                                      select: function ($event) {
                                        return _vm.onActionClick(action.event)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "KLabeledIcon",
                                      {
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "icon",
                                              fn: function () {
                                                return [
                                                  _c("KIcon", {
                                                    attrs: {
                                                      icon: action.icon,
                                                      color: action.iconColor,
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(action.label)),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              })
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("CoachContentLabel", {
            staticStyle: { "margin-top": "8px", width: "auto" },
            attrs: { value: _vm.isCoachContent },
          }),
          _c(
            "KLabeledIcon",
            {
              style: { "margin-top": "8px" },
              scopedSlots: _vm._u([
                {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("LearningActivityIcon", {
                        attrs: {
                          "data-test": "learningActivityIcon",
                          kind: _vm.learningActivities,
                          shaded: true,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.windowBreakpoint <= 3
                ? _c("TextTruncatorCss", {
                    attrs: {
                      text: _vm._f("truncateText")(_vm.resourceTitle, 50),
                      maxLines: 1,
                    },
                  })
                : _c("TextTruncatorCss", {
                    attrs: {
                      text: _vm._f("truncateText")(_vm.resourceTitle, 70),
                      maxLines: 1,
                    },
                  }),
            ],
            1
          ),
          _c("ProgressIcon", {
            staticClass: "progress-icon",
            attrs: { progress: _vm.contentProgress },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }