var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h2", { staticClass: "title" }, [
        _vm._v("\n    " + _vm._s(_vm.$tr("activities")) + "\n  "),
      ]),
      _vm._l(_vm.availableLearningActivities, function (key, activity) {
        return _c(
          "span",
          { key: key, attrs: { alignment: "center" } },
          [
            _c(
              "KButton",
              {
                attrs: {
                  appearance: "flat-button",
                  appearanceOverrides: _vm.isKeyActive(key)
                    ? Object.assign(
                        {},
                        _vm.activityStyles,
                        _vm.activityActiveStyles
                      )
                    : _vm.activityStyles,
                  disabled:
                    _vm.availableActivities &&
                    !_vm.availableActivities[key] &&
                    !_vm.isKeyActive(key),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("input", key)
                  },
                },
              },
              [
                _c("KIcon", {
                  staticClass: "activity-icon",
                  attrs: { icon: _vm.activityIcon(activity) },
                }),
                _c("p", { staticClass: "activity-button-text" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.coreString(_vm.camelCase(activity))) +
                      "\n      "
                  ),
                ]),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }