var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LearnAppBarPage",
    { attrs: { appBarTitle: _vm.learnString("learnLabel") } },
    [
      !_vm.loading
        ? _c(
            "div",
            { attrs: { id: "main", role: "main" } },
            [
              _vm.missingResources
                ? _c("ResourceSyncingUiAlert", {
                    on: { syncComplete: _vm.hydrateHomePage },
                  })
                : _vm._e(),
              _vm.displayClasses
                ? _c("YourClasses", {
                    staticClass: "section",
                    attrs: {
                      classes: _vm.classes,
                      "data-test": "classes",
                      short: "",
                    },
                  })
                : _vm._e(),
              _vm.continueLearning
                ? _c("ContinueLearning", {
                    staticClass: "section",
                    attrs: {
                      fromClasses: _vm.continueLearningFromClasses,
                      "data-test": _vm.continueLearningFromClasses
                        ? "continueLearningFromClasses"
                        : "continueLearningOnYourOwn",
                    },
                  })
                : _vm._e(),
              _vm.hasActiveClassesLessons
                ? _c("AssignedLessonsCards", {
                    staticClass: "section",
                    attrs: {
                      lessons: _vm.activeClassesLessons,
                      displayClassName: "",
                      recent: "",
                      "data-test": "recentLessons",
                    },
                  })
                : _vm._e(),
              _vm.hasActiveClassesQuizzes
                ? _c("AssignedQuizzesCards", {
                    staticClass: "section",
                    attrs: {
                      quizzes: _vm.activeClassesQuizzes,
                      displayClassName: "",
                      recent: "",
                      "data-test": "recentQuizzes",
                    },
                  })
                : _vm._e(),
              _vm.displayExploreChannels
                ? _c("ExploreChannels", {
                    staticClass: "section",
                    attrs: {
                      channels: _vm.channels,
                      "data-test": "exploreChannels",
                      short: Boolean(
                        _vm.displayClasses ||
                          _vm.continueLearning ||
                          _vm.hasActiveClassesLessons ||
                          _vm.hasActiveClassesQuizzes
                      ),
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }