var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.copies.length
    ? _c(
        "KModal",
        {
          attrs: {
            title: _vm.$tr("copies"),
            submitText: _vm.coreString("closeAction"),
          },
          on: {
            submit: function ($event) {
              return _vm.$emit("closeModal")
            },
          },
        },
        [
          _c("transition", { attrs: { mode: "out-in" } }, [
            _c(
              "ul",
              _vm._l(_vm.copies, function (copy, index) {
                return _c("li", { key: index, staticClass: "copy" }, [
                  _c(
                    "div",
                    { staticClass: "title" },
                    [
                      _c("KRouterLink", {
                        attrs: { text: copy.title, to: _vm.contentLink(copy) },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "ol",
                    _vm._l(copy.ancestors, function (ancestor, index2) {
                      return _c(
                        "li",
                        {
                          key: index2,
                          staticClass: "ancestor",
                          class: { arrow: index2 < copy.ancestors.length - 1 },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(ancestor.title) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }