var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.contentNodeRoute ? "KRouterLink" : "div",
    {
      tag: "component",
      class: [
        "resource-item",
        _vm.$computedClass({ ":focus": _vm.$coreOutline }),
      ],
      attrs: { to: _vm.contentNodeRoute },
    },
    [
      _vm.size === "small"
        ? _c("ResourceLabel", {
            attrs: {
              contentNode: _vm.contentNode,
              contentNodeRoute: _vm.contentNodeRoute,
              disableLinkFocus: "",
            },
          })
        : _vm.size === "medium"
        ? _c(
            "KFixedGrid",
            { attrs: { numCols: "2", gutter: "16" } },
            [
              _c(
                "KFixedGridItem",
                { attrs: { span: "1" } },
                [
                  _c("ContentNodeThumbnail", {
                    attrs: { contentNode: _vm.contentNode },
                  }),
                ],
                1
              ),
              _c(
                "KFixedGridItem",
                { style: { position: "relative" }, attrs: { span: "1" } },
                [
                  _c("ResourceLabel", {
                    style: {
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                    },
                    attrs: {
                      contentNode: _vm.contentNode,
                      contentNodeRoute: _vm.contentNodeRoute,
                      disableLinkFocus: "",
                      hideIcon: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm.size === "large"
        ? [
            _c("ContentNodeThumbnail", {
              attrs: { contentNode: _vm.contentNode },
            }),
            _c("ResourceLabel", {
              style: { marginTop: "16px" },
              attrs: {
                contentNode: _vm.contentNode,
                contentNodeRoute: _vm.contentNodeRoute,
                disableLinkFocus: "",
                condensed: "",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }