var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LearnAppBarPage",
    { attrs: { appBarTitle: _vm.learnString("learnLabel") } },
    [
      _c("h1", [_vm._v(_vm._s(_vm.$tr("header")))]),
      _c(
        "p",
        [
          _vm.deviceContentUrl
            ? _c("KExternalLink", {
                attrs: {
                  text: _vm.$tr("adminLink"),
                  href: _vm.deviceContentUrl,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showLearnerText
        ? _c("p", [_vm._v("\n    " + _vm._s(_vm.$tr("learnerText")) + "\n  ")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }