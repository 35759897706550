var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.quiz
    ? _c(
        "BaseCard",
        _vm._b(
          {
            scopedSlots: _vm._u(
              [
                _vm.showThumbnail
                  ? {
                      key: "topLeft",
                      fn: function () {
                        return [_c("QuizThumbnail", { attrs: { rounded: "" } })]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "BaseCard",
          {
            to: _vm.to,
            title: _vm.title,
            collectionTitle: _vm.collectionTitle,
            completedLabel: _vm.completedLabel,
            inProgressLabel: _vm.inProgressLabel,
          },
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }