var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "header",
      staticClass: "header",
      style: {
        backgroundColor: _vm.$themeTokens.surface,
      },
    },
    [
      _c(
        "KGrid",
        { attrs: { gutter: "0" } },
        [
          _c(
            "KGridItem",
            {
              staticClass: "breadcrumbs",
              attrs: {
                "data-test": "header-breadcrumbs",
                layout4: { span: 4 },
                layout8: { span: 8 },
                layout12: { span: 12 },
              },
            },
            [
              _vm.breadcrumbs.length
                ? _c("KBreadcrumbs", {
                    attrs: {
                      items: _vm.breadcrumbs,
                      ariaLabel: _vm.learnString("channelAndFoldersLabel"),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "KGridItem",
            {
              attrs: {
                layout4: { span: 4, alignment: "auto" },
                layout8: { span: 8, alignment: "auto" },
                layout12: { span: 12, alignment: "auto" },
              },
            },
            [
              _c(
                "h1",
                {
                  staticClass: "title",
                  attrs: { "data-test": "header-title" },
                },
                [
                  _c("TextTruncatorCss", {
                    attrs: { text: _vm.title, maxLines: 1 },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.thumbnail
            ? _c(
                "KGridItem",
                {
                  staticClass: "thumbnail",
                  attrs: {
                    layout4: { span: 1 },
                    layout8: { span: 2 },
                    layout12: { span: 2 },
                  },
                },
                [
                  _c("ChannelThumbnail", {
                    staticClass: "thumbnail",
                    attrs: {
                      thumbnail: _vm.thumbnail,
                      isMobile: _vm.windowIsSmall,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.description
            ? _c(
                "KGridItem",
                {
                  staticClass: "text",
                  attrs: {
                    layout4: { span: _vm.thumbnail ? 3 : 4, alignment: "auto" },
                    layout8: { span: _vm.thumbnail ? 6 : 8, alignment: "auto" },
                    layout12: {
                      span: _vm.thumbnail ? 10 : 12,
                      alignment: "auto",
                    },
                  },
                },
                [
                  _c("TextTruncatorCss", {
                    staticClass: "text-description",
                    attrs: { text: _vm.description, maxLines: 4 },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", [_vm._t("sticky-sidebar")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }