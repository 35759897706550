var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title: _vm.learnString("markResourceAsCompleteLabel"),
        submitText: _vm.coreString("confirmAction"),
        cancelText: _vm.coreString("cancelAction"),
      },
      on: {
        submit: function ($event) {
          return _vm.$emit("complete")
        },
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
    },
    [
      !_vm.showLoading
        ? _c("div", [
            _vm._v(
              "\n    " +
                _vm._s(_vm.$tr("markResourceAsCompleteConfirmation")) +
                "\n  "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }