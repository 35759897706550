var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.$isPrint,
          expression: "!$isPrint",
        },
      ],
      staticClass: "tab-block",
      style: {
        backgroundColor: _vm.$themeTokens.surface,
        borderBottom: "1px solid " + _vm.$themeTokens.fineLine,
      },
    },
    [
      _vm.topics.length && _vm.windowIsLarge
        ? _c(
            "router-link",
            {
              staticClass: "header-tab",
              class: _vm.defaultTabStyles,
              style: {
                color: _vm.$themeTokens.annotation,
                marginLeft: _vm.width > 234 ? "12px" : "0",
                marginRight: _vm.width > 234 ? "12px" : "0",
              },
              attrs: {
                to: _vm.foldersLink,
                activeClass: _vm.activeTabClasses,
                replace: true,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "inner",
                  style: { borderColor: this.$themeTokens.primary },
                },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.coreString("folders")) + "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm.windowIsLarge
        ? _c(
            "router-link",
            {
              staticClass: "header-tab",
              class: _vm.defaultTabStyles,
              style: {
                color: _vm.$themeTokens.annotation,
                marginLeft: _vm.width > 234 ? "12px" : "0",
                marginRight: _vm.width > 234 ? "12px" : "0",
              },
              attrs: {
                to: _vm.topics.length ? _vm.searchTabLink : {},
                activeClass: _vm.activeTabClasses,
                replace: true,
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "inner",
                  style: { borderColor: this.$themeTokens.primary },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.coreString("searchLabel")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }