var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "history-list" },
    _vm._l(_vm.questions, function (question, index) {
      return _c(
        "li",
        {
          key: index,
          ref: "item-" + index,
          refInFor: true,
          staticClass: "list-item",
        },
        [
          _c(
            "button",
            {
              staticClass: "clickable",
              class: _vm.buttonClass(index),
              attrs: { disabled: _vm.questionNumber === index },
              on: {
                click: function ($event) {
                  return _vm.$emit("goToQuestion", index)
                },
              },
            },
            [
              _c("KIcon", {
                staticClass: "dot",
                attrs: {
                  icon: "notStarted",
                  color: _vm.isAnswered(question)
                    ? _vm.$themeTokens.progress
                    : _vm.$themeTokens.textDisabled,
                },
              }),
              _c("div", { staticClass: "text" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.questionText(index + 1)) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }