var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "thumbnail", style: _vm.thumbnailStyles },
    [
      _c("span", { staticClass: "icon" }, [_vm._t("icon")], 2),
      _vm.thumbnailUrl
        ? _c("img", {
            staticClass: "image",
            attrs: { src: _vm.thumbnailUrl, alt: "" },
          })
        : _vm._e(),
      _vm._t("labels"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }