var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { maxWidth: "1700px" } },
    [
      _c(
        "transition",
        { attrs: { name: "delay-entry" } },
        [
          !(_vm.rootNodesLoading || _vm.searchLoading) &&
          _vm.welcomeModalVisible
            ? _c("PostSetupModalGroup", {
                attrs: { isOnMyOwnUser: "" },
                on: { cancel: _vm.hideWelcomeModal },
              })
            : _vm.usingMeteredConnection
            ? _c("MeteredConnectionNotificationModal", {
                on: {
                  update: function (value) {
                    return (_vm.allowDownloadOnMeteredConnection = value)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "LearnAppBarPage",
        {
          attrs: {
            appBarTitle: _vm.appBarTitle,
            loading: _vm.rootNodesLoading,
            appearanceOverrides: {},
            deviceId: _vm.deviceId,
            route: _vm.back,
          },
        },
        [
          _c(
            "main",
            { staticClass: "main-grid", style: _vm.gridOffset },
            [
              !_vm.windowIsLarge && (!_vm.isLocalLibraryEmpty || _vm.deviceId)
                ? _c(
                    "div",
                    [
                      _c("KButton", {
                        staticClass: "filter-button",
                        attrs: {
                          icon: "filter",
                          "data-test": "filter-button",
                          text: _vm.coreString("filter"),
                          primary: false,
                        },
                        on: { click: _vm.toggleSidePanelVisibility },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rootNodesLoading || _vm.searchLoading
                ? _c("KCircularLoader", {
                    staticClass: "loader",
                    attrs: { type: "indeterminate", delay: false },
                  })
                : !_vm.displayingSearchResults && !_vm.rootNodesLoading
                ? _c(
                    "div",
                    { attrs: { "data-test": "channels" } },
                    [
                      _c("h1", { staticClass: "channels-label" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.channelsLabel) +
                            "\n        "
                        ),
                      ]),
                      _vm.isLocalLibraryEmpty
                        ? _c(
                            "p",
                            {
                              staticClass: "nothing-in-lib-label",
                              attrs: { "data-test": "nothing-in-lib-label" },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.coreString("nothingInLibraryLearner")
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.isLocalLibraryEmpty
                        ? _c("ChannelCardGroupGrid", {
                            staticClass: "grid",
                            attrs: {
                              "data-test": "channel-cards",
                              contents: _vm.rootNodes,
                              deviceId: _vm.deviceId,
                            },
                          })
                        : _vm._e(),
                      !_vm.deviceId
                        ? _c("ResumableContentGrid", {
                            attrs: {
                              "data-test": "resumable-content",
                              currentCardViewStyle: _vm.currentCardViewStyle,
                            },
                            on: {
                              setCardStyle: function (style) {
                                return (_vm.currentCardViewStyle = style)
                              },
                              setSidePanelMetadataContent: function (content) {
                                return (_vm.metadataSidePanelContent = content)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm.showOtherLibraries
                        ? _c("OtherLibraries", {
                            attrs: {
                              "data-test": "other-libraries",
                              injectedtr: _vm.injecttr,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.displayingSearchResults
                ? _c("SearchResultsGrid", {
                    attrs: {
                      "data-test": "search-results",
                      allowDownloads: _vm.allowDownloads,
                      results: _vm.results,
                      removeFilterTag: _vm.removeFilterTag,
                      clearSearch: _vm.clearSearch,
                      moreLoading: _vm.moreLoading,
                      searchMore: _vm.searchMore,
                      currentCardViewStyle: _vm.currentCardViewStyle,
                      searchTerms: _vm.searchTerms,
                      searchLoading: _vm.searchLoading,
                      more: _vm.more,
                    },
                    on: {
                      setCardStyle: function (style) {
                        return (_vm.currentCardViewStyle = style)
                      },
                      setSidePanelMetadataContent: function (content) {
                        return (_vm.metadataSidePanelContent = content)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          (!_vm.isLocalLibraryEmpty || _vm.deviceId) &&
          (_vm.windowIsLarge || _vm.mobileSidePanelIsOpen)
            ? _c("SearchFiltersPanel", {
                ref: "sidePanel",
                attrs: {
                  "data-test": "side-panel",
                  width: _vm.sidePanelWidth + "px",
                },
                on: { close: _vm.toggleSidePanelVisibility },
                model: {
                  value: _vm.searchTerms,
                  callback: function ($$v) {
                    _vm.searchTerms = $$v
                  },
                  expression: "searchTerms",
                },
              })
            : _vm._e(),
          _vm.metadataSidePanelContent
            ? _c(
                "SidePanelModal",
                {
                  attrs: {
                    "data-test": "side-panel-modal",
                    alignment: "right",
                  },
                  on: {
                    closePanel: function ($event) {
                      _vm.metadataSidePanelContent = null
                    },
                    shouldFocusFirstEl: function ($event) {
                      return _vm.findFirstEl()
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.metadataSidePanelContent.learning_activities.length
                        ? {
                            key: "header",
                            fn: function () {
                              return _vm._l(
                                _vm.metadataSidePanelContent
                                  .learning_activities,
                                function (activity) {
                                  return _c(
                                    "div",
                                    {
                                      key: activity,
                                      staticClass: "side-panel-chips",
                                      class: _vm.$computedClass({
                                        "::after": {
                                          content: "",
                                          flex: "auto",
                                        },
                                      }),
                                    },
                                    [
                                      _c("LearningActivityChip", {
                                        staticClass: "chip",
                                        staticStyle: {
                                          "margin-left": "8px",
                                          "margin-bottom": "8px",
                                        },
                                        attrs: { kind: activity },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              )
                            },
                            proxy: true,
                          }
                        : null,
                    ],
                    null,
                    true
                  ),
                },
                [
                  _c("BrowseResourceMetadata", {
                    ref: "resourcePanel",
                    attrs: {
                      content: _vm.metadataSidePanelContent,
                      showLocationsInChannel: true,
                      canDownloadExternally:
                        _vm.canDownloadExternally && !_vm.deviceId,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }