var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseToolbar", { staticStyle: { "z-index": "7" } }, [
    _c(
      "div",
      {
        staticClass: "container",
        style: { flexWrap: _vm.windowBreakpoint > 0 ? "nowrap" : "wrap" },
      },
      [
        _c("TextTruncatorCss", {
          staticClass: "requirements",
          attrs: {
            text: _vm.coreString("shortExerciseGoalDescription", {
              count: _vm.requiredCorrectAnswers,
            }),
          },
        }),
        _c("span", [_vm._t("hint")], 2),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }