var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.windowIsLarge ? "section" : "SidePanelModal",
    {
      tag: "component",
      class: _vm.windowIsLarge ? "side-panel" : "",
      style: _vm.windowIsLarge
        ? {
            color: _vm.$themeTokens.text,
            backgroundColor: _vm.$themeTokens.surface,
            width: _vm.width,
          }
        : {},
      attrs: {
        alignment: "left",
        role: "region",
        closeButtonIconType: _vm.closeButtonIcon,
        "aria-label": _vm.learnString("filterAndSearchLabel"),
        ariaLabel: _vm.learnString("filterAndSearchLabel"),
      },
      on: {
        closePanel: function ($event) {
          _vm.currentCategory
            ? (_vm.currentCategory = null)
            : _vm.$emit("close")
        },
        shouldFocusFirstEl: function ($event) {
          return _vm.focusFirstEl()
        },
      },
    },
    [
      _vm.windowIsLarge || !_vm.currentCategory
        ? _c(
            "div",
            { class: _vm.windowIsLarge ? "" : "drawer-panel" },
            [
              _c("h2", { staticClass: "title" }, [
                _vm._v("\n      " + _vm._s(_vm.$tr("keywords")) + "\n    "),
              ]),
              _c("SearchBox", {
                key: "channel-search",
                ref: "searchBox",
                attrs: {
                  placeholder: "findSomethingToLearn",
                  value: _vm.value.keywords || "",
                },
                on: {
                  change: function (val) {
                    return _vm.$emit(
                      "input",
                      Object.assign({}, _vm.value, { keywords: val })
                    )
                  },
                },
              }),
              Object.keys(_vm.availableLibraryCategories).length
                ? _c(
                    "div",
                    [
                      _c("h2", { staticClass: "section title" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$tr("categories")) +
                            "\n      "
                        ),
                      ]),
                      _vm._l(
                        _vm.availableLibraryCategories,
                        function (category, key) {
                          return _c(
                            "div",
                            {
                              key: key,
                              staticClass: "category-list-item",
                              attrs: { span: "4" },
                            },
                            [
                              _c("KButton", {
                                attrs: {
                                  text: _vm.coreString(category.value),
                                  appearance: "flat-button",
                                  appearanceOverrides: _vm.isCategoryActive(
                                    category.value
                                  )
                                    ? Object.assign(
                                        {},
                                        _vm.categoryListItemStyles,
                                        _vm.categoryListItemActiveStyles
                                      )
                                    : _vm.categoryListItemStyles,
                                  disabled:
                                    _vm.availableRootCategories &&
                                    !_vm.availableRootCategories[
                                      category.value
                                    ] &&
                                    !_vm.isCategoryActive(category.value),
                                  iconAfter: _vm.hasNestedCategories(key)
                                    ? "chevronRight"
                                    : null,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCategory(key)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "category-list-item",
                          attrs: { span: "4" },
                        },
                        [
                          _c("KButton", {
                            attrs: {
                              text: _vm.coreString("uncategorized"),
                              appearance: "flat-button",
                              appearanceOverrides: _vm.isCategoryActive(
                                "no_categories"
                              )
                                ? Object.assign(
                                    {},
                                    _vm.categoryListItemStyles,
                                    _vm.categoryListItemActiveStyles
                                  )
                                : _vm.categoryListItemStyles,
                            },
                            on: { click: _vm.noCategories },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
              _c("ActivityButtonsGroup", {
                staticClass: "section",
                on: { input: _vm.handleActivity },
              }),
              _c("SelectGroup", {
                staticClass: "section",
                attrs: { showChannels: _vm.showChannels },
                model: {
                  value: _vm.inputValue,
                  callback: function ($$v) {
                    _vm.inputValue = $$v
                  },
                  expression: "inputValue",
                },
              }),
              Object.keys(_vm.availableResourcesNeeded).length
                ? _c(
                    "div",
                    { staticClass: "section" },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.coreString("showResources")) +
                            "\n      "
                        ),
                      ]),
                      _vm._l(
                        _vm.availableResourcesNeeded,
                        function (val, activity) {
                          return _c(
                            "div",
                            {
                              key: activity,
                              attrs: { span: "4", alignment: "center" },
                            },
                            [
                              _c("KCheckbox", {
                                attrs: {
                                  checked: _vm.value.learner_needs[val],
                                  label: _vm.coreString(activity),
                                  disabled:
                                    _vm.availableNeeds &&
                                    !_vm.availableNeeds[val],
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleNeed(val)
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        }
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.currentCategory
        ? _c("CategorySearchModal", {
            ref: "searchModal",
            class: _vm.windowIsLarge ? "" : "drawer-panel",
            attrs: { selectedCategory: _vm.currentCategory },
            on: {
              cancel: function ($event) {
                _vm.currentCategory = null
              },
              input: _vm.selectCategory,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }