var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.sessionReady
        ? [
            !_vm.content.assessmentmetadata
              ? _c("KContentRenderer", {
                  staticClass: "content-renderer",
                  attrs: {
                    kind: _vm.content.kind,
                    lang: _vm.content.lang,
                    files: _vm.content.files,
                    options: _vm.content.options,
                    available: _vm.content.available,
                    duration: _vm.content.duration,
                    extraFields: _vm.extra_fields,
                    progress: _vm.progress,
                    userId: _vm.currentUserId,
                    userFullName: _vm.fullName,
                    timeSpent: _vm.time_spent,
                  },
                  on: {
                    startTracking: _vm.startTracking,
                    stopTracking: _vm.stopTracking,
                    updateProgress: _vm.updateProgress,
                    addProgress: _vm.addProgress,
                    updateContentState: _vm.updateContentState,
                    navigateTo: _vm.navigateTo,
                    error: _vm.onError,
                    finished: _vm.onFinished,
                  },
                })
              : _vm.practiceQuiz || _vm.survey
              ? _c("QuizRenderer", {
                  staticClass: "content-renderer",
                  style: { paddingBottom: _vm.windowIsSmall ? "80px" : "0px" },
                  attrs: {
                    content: _vm.content,
                    extraFields: _vm.extra_fields,
                    progress: _vm.progress,
                    userId: _vm.currentUserId,
                    userFullName: _vm.fullName,
                    timeSpent: _vm.time_spent,
                    pastattempts: _vm.pastattempts,
                    mastered: _vm.complete,
                    masteryLevel: _vm.masteryLevel,
                    updateContentSession: _vm.updateContentSession,
                    isSurvey: _vm.survey,
                  },
                  on: {
                    startTracking: _vm.startTracking,
                    stopTracking: _vm.stopTracking,
                    updateInteraction: _vm.updateInteraction,
                    updateProgress: _vm.updateProgress,
                    updateContentState: _vm.updateContentState,
                    repeat: _vm.repeat,
                    error: _vm.onError,
                    finished: _vm.onFinished,
                  },
                })
              : _c("AssessmentWrapper", {
                  staticClass: "content-renderer",
                  attrs: {
                    kind: _vm.content.kind,
                    files: _vm.content.files,
                    lang: _vm.content.lang,
                    randomize: _vm.content.assessmentmetadata.randomize,
                    masteryModel: _vm.content.assessmentmetadata.mastery_model,
                    assessmentIds:
                      _vm.content.assessmentmetadata.assessment_item_ids,
                    available: _vm.content.available,
                    extraFields: _vm.extra_fields,
                    progress: _vm.progress,
                    userId: _vm.currentUserId,
                    userFullName: _vm.fullName,
                    timeSpent: _vm.time_spent,
                    pastattempts: _vm.pastattempts,
                    mastered: _vm.complete,
                    totalattempts: _vm.totalattempts,
                  },
                  on: {
                    startTracking: _vm.startTracking,
                    stopTracking: _vm.stopTracking,
                    updateInteraction: _vm.updateInteraction,
                    updateProgress: _vm.updateProgress,
                    updateContentState: _vm.updateContentState,
                    error: _vm.onError,
                    finished: _vm.onFinished,
                  },
                }),
          ]
        : _vm._e(),
      _vm.showCompletionModal
        ? _c("CompletionModal", {
            ref: "completionModal",
            attrs: {
              isUserLoggedIn: _vm.isUserLoggedIn,
              contentNode: _vm.content,
              lessonId: _vm.lessonId,
              isQuiz: _vm.practiceQuiz,
              isSurvey: _vm.survey,
              wasComplete: _vm.wasComplete,
            },
            on: {
              close: _vm.hideCompletionModal,
              shouldFocusFirstEl: function ($event) {
                return _vm.findFirstEl()
              },
            },
          })
        : _vm._e(),
      _vm.showCompleteContentModal && _vm.allowMarkComplete
        ? _c("MarkAsCompleteModal", {
            on: {
              complete: _vm.handleMarkAsComplete,
              cancel: _vm.hideMarkAsCompleteModal,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }