var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        !_vm.windowIsSmall && _vm.currentCardViewStyle === "list"
          ? "div"
          : "CardGrid",
        {
          tag: "component",
          style: { maxWidth: "1700px" },
          attrs: {
            "data-test": (_vm.windowIsSmall ? "" : "non-") + "mobile-card-grid",
            gridType: _vm.gridType,
          },
        },
        _vm._l(_vm.contents, function (contentNode, idx) {
          return _c(_vm.componentType, {
            key: "resource-" + idx,
            tag: "component",
            attrs: {
              "data-test": _vm.componentType + "-" + idx,
              contentNode: contentNode,
              to: _vm.contentLink(contentNode.id, contentNode.is_leaf),
            },
            on: {
              openCopiesModal: function ($event) {
                return _vm.$emit("openCopiesModal", contentNode.copies)
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c("HybridLearningFooter", {
                        attrs: {
                          contentNode: contentNode,
                          allowDownloads: _vm.allowDownloads,
                        },
                        on: {
                          toggleInfoPanel: function ($event) {
                            return _vm.$emit("toggleInfoPanel", contentNode)
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }