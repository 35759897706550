var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.searchLoading
    ? _c(
        "div",
        [
          _c(
            "h2",
            {
              staticClass: "results-title",
              attrs: { "data-test": "search-results-title" },
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(
                    _vm.more
                      ? _vm.coreString("uncountedAdditionalResults", {
                          num: _vm.results.length,
                        })
                      : _vm.$tr("results", { results: _vm.results.length })
                  ) +
                  "\n  "
              ),
            ]
          ),
          _c("SearchChips", {
            attrs: { searchTerms: _vm.searchTerms },
            on: {
              removeItem: _vm.removeFilterTag,
              clearSearch: _vm.clearSearch,
            },
          }),
          !_vm.windowIsSmall && _vm.results.length && !_vm.hideCardViewToggle
            ? _c(
                "div",
                {
                  staticClass: "toggle-view-buttons",
                  attrs: { "data-test": "toggle-view-buttons" },
                },
                [
                  _c("KIconButton", {
                    attrs: {
                      icon: "menu",
                      ariaLabel: _vm.$tr("viewAsList"),
                      color: _vm.$themeTokens.text,
                      tooltip: _vm.$tr("viewAsList"),
                      disabled: _vm.currentCardViewStyle === "list",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCardView("list")
                      },
                    },
                  }),
                  _c("KIconButton", {
                    attrs: {
                      icon: "channel",
                      ariaLabel: _vm.$tr("viewAsGrid"),
                      color: _vm.$themeTokens.text,
                      tooltip: _vm.$tr("viewAsGrid"),
                      disabled: _vm.currentCardViewStyle === "card",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCardView("card")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("LibraryAndChannelBrowserMainContent", {
            attrs: {
              contents: _vm.results,
              allowDownloads: _vm.allowDownloads,
              "data-test": "search-results-card-grid",
              currentCardViewStyle: _vm.currentCardViewStyle,
              gridType: _vm.gridType,
            },
            on: {
              openCopiesModal: function (copies) {
                return (_vm.displayedCopies = copies)
              },
              toggleInfoPanel: function ($event) {
                return _vm.$emit("setSidePanelMetadataContent", $event)
              },
            },
          }),
          _vm.more
            ? _c("KButton", {
                staticClass: "filter-action-button",
                attrs: {
                  text: _vm.coreString("viewMoreAction"),
                  appearance: "basic-link",
                  disabled: _vm.moreLoading,
                  "data-test": "more-results-button",
                },
                on: { click: _vm.searchMore },
              })
            : _vm._e(),
          _vm.displayedCopies.length
            ? _c("CopiesModal", {
                attrs: { copies: _vm.displayedCopies },
                on: {
                  closeModal: function ($event) {
                    _vm.displayedCopies = []
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }