var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h2",
        { staticClass: "top-category" },
        [
          _c("KButton", {
            attrs: {
              text: _vm.coreString(_vm.camelCase(_vm.selectedCategory)),
              appearanceOverrides: _vm.appearanceOverrides(
                _vm.topLevelCategory.value,
                true
              ),
              appearance: "basic-link",
              disabled:
                _vm.availablePaths &&
                !_vm.availablePaths[_vm.topLevelCategory.value],
            },
            on: {
              click: function ($event) {
                return _vm.$emit("input", _vm.topLevelCategory.value)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "KGrid",
        _vm._l(_vm.displaySelectedCategories, function (nestedObject, key) {
          return _c(
            "KGridItem",
            {
              key: key,
              staticClass: "category-item",
              attrs: {
                layout4: { span: 4 },
                layout8: { span: 8 },
                layout12: { span: 4 },
              },
            },
            [
              _c(
                "div",
                { staticClass: "filter-list-title" },
                [
                  _c("KIcon", {
                    style: { marginLeft: "8px" },
                    attrs: { icon: _vm.icon(key), size: "large" },
                  }),
                  _c(
                    "h3",
                    [
                      _c("KButton", {
                        staticClass: "larger-text",
                        attrs: {
                          text: _vm.coreString(_vm.camelCase(key)),
                          appearance: "basic-link",
                          appearanceOverrides: _vm.appearanceOverrides(
                            nestedObject.value,
                            true
                          ),
                          disabled:
                            _vm.availablePaths &&
                            !_vm.availablePaths[nestedObject.value],
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("input", nestedObject.value)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(nestedObject.nested, function (item, nestedKey) {
                return _c(
                  "div",
                  { key: item.value },
                  [
                    _c("KButton", {
                      attrs: {
                        text: _vm.coreString(_vm.camelCase(nestedKey)),
                        appearanceOverrides: _vm.appearanceOverrides(
                          item.value
                        ),
                        appearance: "basic-link",
                        disabled:
                          _vm.availablePaths && !_vm.availablePaths[item.value],
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", item.value)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }