var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KModal",
    {
      attrs: {
        title: _vm.$tr("welcomeModalHeader"),
        submitText: _vm.coreString("continueAction"),
      },
      on: {
        submit: function ($event) {
          return _vm.$emit("submit")
        },
      },
    },
    _vm._l(_vm.paragraphs, function (paragraph, idx) {
      return _c("p", { key: idx, staticClass: "paragraph" }, [
        _vm._v("\n    " + _vm._s(paragraph) + "\n  "),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }