var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c(
        "section",
        { staticClass: "metadata" },
        [
          _c("div", { staticClass: "section" }, [
            _vm.forBeginners
              ? _c(
                  "span",
                  {
                    staticClass: "beginners-chip",
                    class: _vm.$computedClass(_vm.chipStyle),
                    attrs: { "data-test": "beginners-chip" },
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.coreString("forBeginners")) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "section" },
            [
              _c("ContentNodeThumbnail", {
                attrs: { contentNode: _vm.content },
              }),
            ],
            1
          ),
          _vm.content.title
            ? _c(
                "div",
                {
                  staticClass: "section title",
                  attrs: { "data-test": "content-title" },
                },
                [_vm._v("\n    " + _vm._s(_vm.content.title) + "\n  ")]
              )
            : _vm._e(),
          _vm.content.description
            ? _c(
                "div",
                {
                  ref: "description",
                  staticClass: "content",
                  class: _vm.truncate,
                  attrs: { "data-test": "content-description" },
                },
                [_vm._v("\n    " + _vm._s(_vm.content.description) + "\n  ")]
              )
            : _vm._e(),
          _vm.descriptionOverflow
            ? _c("KButton", {
                staticClass: "show-more-button",
                attrs: {
                  text: _vm.showMoreOrLess,
                  appearance: "flat-button",
                  primary: true,
                },
                on: { click: _vm.toggleShowMoreOrLess },
              })
            : _vm._e(),
          _vm.content.duration
            ? _c(
                "div",
                {
                  staticClass: "section",
                  attrs: { "data-test": "estimated-time" },
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.learnString("estimatedTime")) +
                        ":\n    "
                    ),
                  ]),
                  _c(
                    "span",
                    [
                      _c("TimeDuration", {
                        attrs: { seconds: _vm.content.duration },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.content.grade_levels && _vm.content.grade_levels.length
            ? _c(
                "div",
                {
                  staticClass: "section",
                  attrs: { "data-test": "grade-levels" },
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.coreString("levelLabel")) +
                        ":\n    "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.levels(_vm.content.grade_levels)) +
                        "\n    "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.content.lang
            ? _c(
                "div",
                { staticClass: "section", attrs: { "data-test": "lang" } },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.coreString("languageLabel")) +
                        ":\n    "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.content.lang.lang_name) + "\n    "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.content.author
            ? _c(
                "div",
                { staticClass: "section", attrs: { "data-test": "author" } },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.learnString("author")) + ":\n    "
                    ),
                  ]),
                  _c("span", [
                    _vm._v("\n      " + _vm._s(_vm.content.author) + "\n    "),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.content.license_owner
            ? _c(
                "div",
                {
                  staticClass: "section",
                  attrs: { "data-test": "license-owner" },
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.learnString("copyrightHolder")) +
                        ":\n    "
                    ),
                  ]),
                  _c("span", [
                    _vm._v(
                      "\n      " + _vm._s(_vm.content.license_owner) + "\n    "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.licenseDescription
            ? _c(
                "div",
                {
                  staticClass: "section",
                  attrs: { "data-test": "license-desc" },
                },
                [
                  _c("span", { staticClass: "label" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.learnString("license")) +
                        ":\n    "
                    ),
                  ]),
                  _c(
                    "span",
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm.licenseShortName || "") +
                          "\n      "
                      ),
                      _c("KIconButton", {
                        staticClass: "license-toggle",
                        attrs: {
                          icon: _vm.licenseDescriptionIsVisible
                            ? "chevronUp"
                            : "chevronDown",
                          ariaLabel: _vm.learnString(
                            "toggleLicenseDescription"
                          ),
                          size: "small",
                          type: "secondary",
                        },
                        on: {
                          click: function ($event) {
                            _vm.licenseDescriptionIsVisible =
                              !_vm.licenseDescriptionIsVisible
                          },
                        },
                      }),
                      _vm.licenseDescriptionIsVisible
                        ? _c("div", { staticClass: "license-details" }, [
                            _c("p", { staticClass: "license-details-name" }, [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.licenseLongName) +
                                  "\n        "
                              ),
                            ]),
                            _c("p", [_vm._v(_vm._s(_vm.licenseDescription))]),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.canDownloadExternally
            ? _c("DownloadButton", {
                staticClass: "download-button",
                attrs: {
                  files: _vm.content.files,
                  nodeTitle: _vm.content.title,
                  "data-test": "download-button",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }