var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "channel-thumbnail" }, [
    _vm.thumbnailUrl
      ? _c("img", {
          staticClass: "image",
          attrs: { src: _vm.thumbnailUrl, alt: "" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }