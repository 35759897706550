var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.page,
    {
      tag: "component",
      attrs: {
        appBarTitle: _vm.appBarTitle,
        appearanceOverrides: _vm.appearanceOverrides,
        loading: _vm.loading,
        primary: false,
        route: _vm.route,
        title: _vm.appBarTitle,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "actions",
            fn: function () {
              return [
                _vm.deviceId
                  ? _c("DeviceConnectionStatus", {
                      attrs: { deviceId: _vm.deviceId, color: "white" },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          !_vm.deviceId
            ? {
                key: "subNav",
                fn: function () {
                  return [_c("LearnTopNav", { ref: "topNav" })]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }