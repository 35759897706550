var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReference ||
    _vm.displayPreciseDuration ||
    _vm.displayEstimatedDuration ||
    _vm.exerciseDescription
    ? _c(
        "span",
        {
          class: [_vm.appearance === "chip" ? "chip" : ""],
          style: [
            _vm.appearance === "chip"
              ? {
                  color: _vm.$themeTokens.textInverted,
                  backgroundColor: _vm.$themePalette.black,
                }
              : {},
          ],
        },
        [
          _vm.isReference
            ? [_vm._v("\n    " + _vm._s(_vm.ReferenceLabel) + "\n  ")]
            : _vm.displayPreciseDuration
            ? _c("TimeDuration", { attrs: { seconds: _vm.durationInSeconds } })
            : _vm.displayEstimatedDuration
            ? [_vm._v("\n    " + _vm._s(_vm.durationEstimation) + "\n  ")]
            : _vm.exerciseDescription
            ? [_vm._v("\n    " + _vm._s(_vm.exerciseDescription) + "\n  ")]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }