var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content
    ? _c("ExamReport", {
        staticClass: "report",
        attrs: {
          contentId: _vm.content.content_id,
          title: _vm.content.title,
          duration: _vm.content.duration,
          userName: _vm.userName,
          userId: _vm.userId,
          selectedInteractionIndex: _vm.selectedInteractionIndex,
          questionNumber: _vm.questionNumber,
          tryIndex: _vm.tryIndex,
          exercise: _vm.content,
          exerciseContentNodes: [_vm.content],
          navigateTo: _vm.navigateTo,
          questions: _vm.questions,
          isSurvey: _vm.isSurvey,
          isQuiz: !_vm.isSurvey,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "KButton",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("repeat")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " +
                          _vm._s(
                            _vm.isSurvey
                              ? _vm.$tr("submitAgainButton")
                              : _vm.$tr("tryAgainButton")
                          ) +
                          "\n    "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          663806910
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }