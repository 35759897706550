var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "h2",
        [_c("KLabeledIcon", { attrs: { icon: "forward", label: _vm.header } })],
        1
      ),
      _c(
        "CardGrid",
        { attrs: { gridType: 1 } },
        [
          _vm.fromClasses
            ? [
                _vm._l(
                  _vm.uniqueResumableClassesResources,
                  function (resource, idx) {
                    return _c("ResourceCard", {
                      key: "resource-" + idx,
                      attrs: {
                        contentNode: resource.contentNode,
                        to: _vm.genContentLinkBackLinkCurrentPage(
                          resource.contentNode.id,
                          true
                        ),
                        collectionTitle: _vm.getResourceClassName(resource),
                      },
                    })
                  }
                ),
                _vm._l(_vm.resumableClassesQuizzes, function (quiz, idx) {
                  return _c("QuizCard", {
                    key: "quiz-" + idx,
                    attrs: {
                      quiz: quiz,
                      to: _vm.getClassQuizLink(quiz),
                      collectionTitle: _vm.getQuizClassName(quiz),
                      showThumbnail: "",
                    },
                  })
                }),
              ]
            : _vm._l(_vm.resumableContentNodes, function (contentNode, idx) {
                return _c("ResourceCard", {
                  key: idx,
                  attrs: {
                    contentNode: contentNode,
                    to: _vm.genContentLinkBackLinkCurrentPage(
                      contentNode.id,
                      true
                    ),
                    collectionTitle: _vm.getContentNodeTopicName(contentNode),
                  },
                  on: { openCopiesModal: _vm.openCopiesModal },
                })
              }),
        ],
        2
      ),
      _vm.moreResumableContentNodes
        ? _c(
            "KButton",
            {
              staticStyle: { "margin-top": "16px" },
              attrs: { appearance: "basic-link" },
              on: { click: _vm.fetchMoreResumableContentNodes },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.coreString("viewMoreAction")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm.displayedCopies.length
        ? _c("CopiesModal", {
            attrs: { copies: _vm.displayedCopies },
            on: {
              closeModal: function ($event) {
                _vm.displayedCopies = []
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }