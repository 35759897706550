var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h2",
        [_c("KLabeledIcon", { attrs: { icon: "quiz", label: _vm.header } })],
        1
      ),
      _vm.visibleQuizzes.length > 0
        ? _c(
            "CardGrid",
            { attrs: { gridType: 1 } },
            _vm._l(_vm.visibleQuizzes, function (quiz) {
              return _c("QuizCard", {
                key: quiz.id,
                attrs: {
                  quiz: quiz,
                  to: _vm.getClassQuizLink(quiz),
                  collectionTitle: _vm.displayClassName
                    ? _vm.getQuizClassName(quiz)
                    : "",
                },
              })
            }),
            1
          )
        : _c("p", [
            _vm._v("\n    " + _vm._s(_vm.$tr("noQuizzesMessage")) + "\n  "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }