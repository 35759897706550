var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h2",
        [_c("KLabeledIcon", { attrs: { icon: "lesson", label: _vm.header } })],
        1
      ),
      _vm.lessons && _vm.lessons.length > 0
        ? _c(
            "CardGrid",
            { attrs: { gridType: 1 } },
            _vm._l(_vm.lessons, function (lesson) {
              return _c("LessonCard", {
                key: lesson.id,
                attrs: {
                  lesson: lesson,
                  to: _vm.getClassLessonLink(lesson),
                  collectionTitle: _vm.displayClassName
                    ? _vm.getLessonClassName(lesson)
                    : "",
                },
              })
            }),
            1
          )
        : _c("p", [
            _vm._v("\n    " + _vm._s(_vm.$tr("noLessonsMessage")) + "\n  "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }