var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contentNode
    ? _c(
        "div",
        [
          _c(
            "span",
            {
              staticClass: "learning-activity",
              class: [_vm.condensed || _vm.labelAfter ? "reversed" : ""],
            },
            [
              _c(
                "span",
                { staticClass: "label", attrs: { "data-test": "label" } },
                [_vm._v("\n      " + _vm._s(_vm.label) + "\n    ")]
              ),
              _vm.contentNode.learning_activities
                ? _c(
                    "span",
                    _vm._l(
                      _vm.contentNode.learning_activities,
                      function (learningActivity, idx) {
                        return _c("LearningActivityIcon", {
                          key: idx,
                          staticClass: "icon",
                          attrs: { kind: learningActivity },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
            ]
          ),
          !_vm.hideDuration
            ? _c("LearningActivityDuration", {
                staticClass: "duration",
                class: [_vm.condensed ? "condensed" : ""],
                attrs: { contentNode: _vm.contentNode },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }