var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KGrid",
    { attrs: { gutter: "16" } },
    [
      _vm._l(_vm.contents, function (content) {
        return _c(
          "KGridItem",
          { key: content.id, attrs: { layout: { span: _vm.layoutSpan } } },
          [
            _c("ChannelCard", {
              attrs: {
                isMobile: _vm.windowIsSmall,
                title: content.title || content.name,
                thumbnail: content.thumbnail,
                tagline: content.tagline || content.description,
                numCoachContents: content.num_coach_contents,
                link: _vm.genContentLinkBackLinkCurrentPage(
                  content.id,
                  false,
                  _vm.deviceId
                ),
                isRemote: _vm.isRemote,
              },
            }),
          ],
          1
        )
      }),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }