var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("HorizontalNavBarWithOverflowMenu", {
    ref: "navigation",
    attrs: {
      ariaLabel: _vm.$tr("learnPageMenuLabel"),
      navigationLinks: _vm.links,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }