var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KGrid",
    { staticClass: "library-item", attrs: { debug: false } },
    [
      _c(
        "div",
        {
          class: {
            "library-header": true,
            "library-header-sm": _vm.windowIsSmall,
          },
        },
        [
          _c(
            "KGridItem",
            {
              attrs: {
                layout12: { span: 10 },
                layout8: { span: 6 },
                layout4: { span: 4 },
              },
            },
            [
              _c("div", [
                _c(
                  "h2",
                  { staticClass: "device-name" },
                  [
                    _vm.deviceIcon
                      ? _c("KIcon", {
                          staticClass: "device-icon",
                          attrs: { icon: _vm.deviceIcon },
                        })
                      : _vm._e(),
                    _c("span", [_vm._v(_vm._s(_vm.deviceName))]),
                    _c("KIconButton", {
                      ref: "pinIcon",
                      attrs: {
                        icon: _vm.pinIcon,
                        disabled: _vm.isStudio,
                        appearance: "flat-button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("togglePin", _vm.deviceId)
                        },
                      },
                    }),
                    _c(
                      "KTooltip",
                      { attrs: { reference: "pinIcon", refs: _vm.$refs } },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.pinIcon === "pinned"
                                ? _vm.$tr("removePin")
                                : _vm.$tr("pinTo")
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm.isStudio
                  ? _c("p", { staticClass: "device-description" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$tr("channels", { channels: _vm.totalChannels })
                          ) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]),
            ]
          ),
          _c(
            "KGridItem",
            {
              attrs: {
                layout12: { span: 2, alignment: "right" },
                layout8: { span: 2, alignment: "right" },
                layout4: { span: 4, alignment: "right" },
              },
            },
            [
              _c("KRouterLink", {
                attrs: {
                  appearance: "raised-button",
                  text: _vm.coreString("explore"),
                  to: _vm.genLibraryPageBackLink(_vm.deviceId),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ChannelCardGroupGrid", {
        attrs: {
          "data-test": "channel-cards",
          deviceId: _vm.device.id,
          contents: (_vm.channels || []).slice(0, _vm.channelsToDisplay),
          isRemote: true,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }