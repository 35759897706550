var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return (_vm.resumableContentNodes || []).length
    ? _c(
        "div",
        [
          !_vm.windowIsSmall
            ? _c(
                "div",
                {
                  staticClass: "toggle-view-buttons",
                  attrs: { "data-test": "toggle-view-buttons" },
                },
                [
                  _c("KIconButton", {
                    attrs: {
                      icon: "menu",
                      ariaLabel: _vm.$tr("viewAsList"),
                      color: _vm.$themeTokens.text,
                      tooltip: _vm.$tr("viewAsList"),
                      disabled: _vm.currentCardViewStyle === "list",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCardView("list")
                      },
                    },
                  }),
                  _c("KIconButton", {
                    attrs: {
                      icon: "channel",
                      ariaLabel: _vm.$tr("viewAsGrid"),
                      color: _vm.$themeTokens.text,
                      tooltip: _vm.$tr("viewAsGrid"),
                      disabled: _vm.currentCardViewStyle === "card",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.toggleCardView("card")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { attrs: { "data-test": "recent-content-nodes-title" } },
            [
              _c("h2", [
                _vm._v("\n      " + _vm._s(_vm.$tr("recent")) + "\n    "),
              ]),
              _c("LibraryAndChannelBrowserMainContent", {
                staticClass: "resumable-content-card-grid",
                attrs: {
                  contents: _vm.contentCardsToDisplay,
                  "data-test": "resumable-content-card-grid",
                  currentCardViewStyle: _vm.currentCardViewStyle,
                  gridType: _vm.gridType,
                },
                on: {
                  openCopiesModal: function (copies) {
                    return (_vm.displayedCopies = copies)
                  },
                  toggleInfoPanel: function ($event) {
                    return _vm.$emit("setSidePanelMetadataContent", $event)
                  },
                },
              }),
            ],
            1
          ),
          _vm.moreContentCards && !_vm.showMoreContentCards
            ? _c(
                "KButton",
                {
                  attrs: {
                    "data-test": "show-more-resumable-nodes-button",
                    appearance: "basic-link",
                  },
                  on: { click: _vm.handleShowMoreContentCards },
                },
                [
                  _vm._v(
                    "\n    " + _vm._s(_vm.coreString("showMoreAction")) + "\n  "
                  ),
                ]
              )
            : _vm._e(),
          _vm.moreResumableContentNodes && _vm.showMoreContentCards
            ? _c(
                "KButton",
                {
                  attrs: {
                    "data-test": "view-more-resumable-nodes-button",
                    appearance: "basic-link",
                  },
                  on: { click: _vm.fetchMoreResumableContentNodes },
                },
                [
                  _vm._v(
                    "\n    " + _vm._s(_vm.coreString("viewMoreAction")) + "\n  "
                  ),
                ]
              )
            : _vm._e(),
          _vm.displayedCopies.length
            ? _c("CopiesModal", {
                attrs: { copies: _vm.displayedCopies },
                on: {
                  closeModal: function ($event) {
                    _vm.displayedCopies = []
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }