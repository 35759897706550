var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ImmersivePage",
    {
      attrs: {
        appBarTitle: _vm.learnString("exploreLibraries"),
        route: _vm.back,
        primary: false,
        loading: _vm.loading,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "page-header",
          style: _vm.pageHeaderStyle,
          attrs: { "data-test": "page-header" },
        },
        [
          _c("h1", [
            _vm._v("\n      " + _vm._s(_vm.$tr("allLibraries")) + "\n    "),
          ]),
          _c("p", [
            _vm._v("\n      " + _vm._s(_vm.$tr("showingLibraries")) + "\n    "),
          ]),
        ]
      ),
      _vm.loading
        ? _c("div", [_c("KCircularLoader")], 1)
        : _c(
            "FadeInTransitionGroup",
            [
              _vm._l(_vm.pinnedDevices, function (device) {
                return _c("LibraryItem", {
                  key: device["instance_id"],
                  attrs: {
                    device: device,
                    channels: _vm.deviceChannelsMap[device["instance_id"]],
                    channelsToDisplay: _vm.cardsToDisplay,
                    pinned: true,
                  },
                  on: { togglePin: _vm.handlePinToggle },
                })
              }),
              _vm.areMoreDevicesAvailable
                ? _c(
                    "div",
                    { key: "moreDevices" },
                    [
                      _vm.pinnedDevicesExist
                        ? _c(
                            "div",
                            { attrs: { "data-test": "more-libraries" } },
                            [
                              _c("h2", [
                                _vm._v(
                                  _vm._s(_vm.learnString("moreLibraries"))
                                ),
                              ]),
                              _vm.displayShowButton
                                ? _c("KButton", {
                                    attrs: {
                                      "data-test": "show-button",
                                      text: _vm.coreString("showAction"),
                                      primary: false,
                                    },
                                    on: { click: _vm.loadMoreDevices },
                                  })
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "FadeInTransitionGroup",
                        _vm._l(
                          _vm.unpinnedDevices.slice(0, _vm.moreDevices),
                          function (device) {
                            return _c("LibraryItem", {
                              key: device["instance_id"],
                              attrs: {
                                device: device,
                                channels:
                                  _vm.deviceChannelsMap[device["instance_id"]],
                                channelsToDisplay: _vm.cardsToDisplay,
                                pinned: false,
                              },
                              on: { togglePin: _vm.handlePinToggle },
                            })
                          }
                        ),
                        1
                      ),
                      _vm.displayShowMoreButton
                        ? _c("KButton", {
                            attrs: {
                              "data-test": "show-more-button",
                              text: _vm.coreString("showMoreAction"),
                              primary: false,
                            },
                            on: { click: _vm.loadMoreDevices },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }