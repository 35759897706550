var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LearnAppBarPage",
    { attrs: { appBarTitle: _vm.learnString("learnLabel") } },
    [
      _vm.loading
        ? _c("KCircularLoader")
        : _c(
            "div",
            { attrs: { id: "main", role: "main" } },
            [
              _c("KBreadcrumbs", {
                attrs: {
                  items: _vm.breadcrumbs,
                  ariaLabel: _vm.learnString("classesAndAssignmentsLabel"),
                },
              }),
              _c(
                "h1",
                { staticClass: "classroom-name" },
                [
                  _c("KLabeledIcon", {
                    attrs: { icon: "classes", label: _vm.className },
                  }),
                ],
                1
              ),
              _c("AssignedLessonsCards", {
                attrs: { lessons: _vm.activeLessons },
              }),
              _c("AssignedQuizzesCards", {
                style: { marginTop: "44px" },
                attrs: { quizzes: _vm.activeQuizzes },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }