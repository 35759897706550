var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.windowIsLarge
        ? _c(
            "Teleport",
            { attrs: { to: "body" } },
            [
              _c(
                "KModal",
                {
                  attrs: {
                    title: _vm.$tr("title"),
                    cancelText: _vm.coreString("closeAction"),
                    size: "large",
                  },
                  on: {
                    cancel: function ($event) {
                      return _vm.$emit("cancel")
                    },
                  },
                },
                [
                  _c(
                    "CategorySearchOptions",
                    _vm._g(
                      {
                        ref: "searchOptions",
                        attrs: { selectedCategory: _vm.selectedCategory },
                      },
                      _vm.$listeners
                    )
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("h2", [_vm._v(_vm._s(_vm.$tr("title")))]),
              _c(
                "CategorySearchOptions",
                _vm._g(
                  {
                    ref: "searchOptions",
                    attrs: { selectedCategory: _vm.selectedCategory },
                  },
                  _vm.$listeners
                )
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }