var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.progress
    ? _c(
        "div",
        {
          staticClass: "progress-bar-wrapper",
          attrs: {
            role: "progressbar",
            "aria-valuemin": 0,
            "aria-valuemax": 100,
            "aria-valuenow": _vm.progress * 100,
          },
        },
        [
          _vm.completed
            ? _c(
                "p",
                {
                  staticClass: "completion-label",
                  style: { color: _vm.$themePalette.grey.v_800 },
                },
                [
                  _c("ProgressIcon", {
                    staticClass: "completion-icon",
                    attrs: { progress: _vm.progress },
                  }),
                  _vm._v(
                    "\n    " + _vm._s(_vm.coreString("completedLabel")) + "\n  "
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.progress && !_vm.completed
            ? _c("KLinearLoader", {
                staticClass: "k-linear-loader",
                style: { backgroundColor: _vm.$themeTokens.fineLine },
                attrs: {
                  delay: false,
                  progress: _vm.progress * 100,
                  type: "determinate",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }