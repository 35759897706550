var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.step === _vm.Steps.WELCOME && _vm.isUserLoggedIn
        ? _c("WelcomeModal", {
            attrs: {
              importedFacility: _vm.importedFacility,
              isOnMyOwnUser: _vm.isOnMyOwnUser,
            },
            on: { submit: _vm.handleSubmit },
          })
        : _vm._e(),
      _vm.step === _vm.Steps.PERMISSIONS_CHANGE
        ? _c("PermissionsChangeModal", { attrs: { newRole: "superadmin" } })
        : _vm._e(),
      _vm.addingAddress
        ? _c("AddDeviceForm", {
            on: {
              cancel: function ($event) {
                _vm.addingAddress = false
              },
              added_address: _vm.handleAddedAddress,
            },
          })
        : _vm.step === _vm.Steps.SELECT_SOURCE_FACILITY_PEER
        ? _c("SelectDeviceForm", {
            attrs: { title: _vm.getCommonSyncString("selectSourceTitle") },
            on: {
              submit: _vm.handleSubmit,
              click_add_address: _vm.goToAddAddress,
              cancel: function ($event) {
                return _vm.$emit("cancel")
              },
            },
            scopedSlots: _vm._u([
              {
                key: "underbuttons",
                fn: function () {
                  return [
                    _c("KButton", {
                      attrs: {
                        text: _vm.$tr("chooseAnotherSourceLabel"),
                        appearance: "basic-link",
                      },
                      on: { click: _vm.startNormalImportWorkflow },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }