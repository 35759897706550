var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "footer" },
    [
      _c("ProgressBar", {
        staticClass: "progress-bar",
        style: {
          maxWidth: "calc(100% - " + (24 + 32 * _vm.footerLength) + "px)",
        },
        attrs: { contentNode: _vm.contentNode },
      }),
      _c(
        "div",
        { staticClass: "footer-icons" },
        [
          _vm.downloadableByLearner
            ? _c("KIconButton", {
                attrs: {
                  icon: "download",
                  size: "mini",
                  color: _vm.$themePalette.grey.v_600,
                  ariaLabel: _vm.coreString("downloadAction"),
                  tooltip: _vm.coreString("downloadAction"),
                },
                on: { click: _vm.handleDownloadRequest },
              })
            : _vm._e(),
          _vm.bookmarked
            ? _c("KIconButton", {
                attrs: {
                  icon: "close",
                  size: "mini",
                  color: _vm.$themePalette.grey.v_600,
                  ariaLabel: _vm.coreString("removeFromBookmarks"),
                  tooltip: _vm.coreString("removeFromBookmarks"),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("removeFromBookmarks")
                  },
                },
              })
            : _vm._e(),
          _vm.isUserLoggedIn &&
          !_vm.isLearner &&
          _vm.contentNode.num_coach_contents
            ? _c("CoachContentLabel", {
                staticClass: "coach-content-label",
                style: _vm.coachContentLabelStyles,
                attrs: {
                  value: _vm.contentNode.num_coach_contents,
                  isTopic: _vm.isTopic,
                },
              })
            : _vm._e(),
          _vm.contentNode.is_leaf
            ? _c("KIconButton", {
                attrs: {
                  icon: "infoOutline",
                  size: "mini",
                  color: _vm.$themePalette.grey.v_600,
                  ariaLabel: _vm.coreString("viewInformation"),
                  tooltip: _vm.coreString("viewInformation"),
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggleInfoPanel")
                  },
                },
              })
            : _vm._e(),
          _vm.downloadedByLearner
            ? _c("KIconButton", {
                ref: "moreOptionsButton",
                attrs: {
                  "data-test": "moreOptionsButton",
                  icon: "optionsHorizontal",
                  tooltip: _vm.coreString("moreOptions"),
                  ariaLabel: _vm.coreString("moreOptions"),
                },
                on: {
                  click: function ($event) {
                    _vm.isMenuOpen = !_vm.isMenuOpen
                  },
                },
              })
            : _vm._e(),
          _c("CoreMenu", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMenuOpen,
                expression: "isMenuOpen",
              },
            ],
            ref: "menu",
            staticClass: "menu",
            style: {
              left: _vm.isRtl ? "16px" : "auto",
              right: _vm.isRtl ? "auto" : "16px",
              position: "absolute",
              zIndex: 7,
            },
            attrs: { raised: true, isOpen: _vm.isMenuOpen, containFocus: true },
            on: {
              close: function ($event) {
                _vm.isMenuOpen = false
              },
              shouldFocusFirstEl: function ($event) {
                return _vm.findFirstEl()
              },
            },
            scopedSlots: _vm._u([
              {
                key: "options",
                fn: function () {
                  return [
                    _c(
                      "CoreMenuOption",
                      {
                        style: { cursor: "pointer" },
                        on: { select: _vm.handleRemoveRequest },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tr("removeFromMyLibraryAction")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm.removeConfirmationModalOpen
        ? _c(
            "KModal",
            {
              attrs: {
                title: _vm.$tr("removeFromMyLibraryAction"),
                cancelText: _vm.coreString("cancelAction"),
                submitText: _vm.coreString("removeAction"),
                size: "medium",
              },
              on: {
                cancel: function ($event) {
                  _vm.removeConfirmationModalOpen = false
                },
                submit: _vm.confirmRemoveRequest,
              },
            },
            [_c("p", [_vm._v(_vm._s(_vm.$tr("removeFromMyLibraryInfo")))])]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }