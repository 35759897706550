var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LearnAppBarPage",
    { attrs: { appBarTitle: _vm.learnString("learnLabel") } },
    [
      !_vm.$store.state.core.loading
        ? _c(
            "div",
            { attrs: { id: "main", role: "main" } },
            [
              _c("KBreadcrumbs", {
                attrs: {
                  items: _vm.breadcrumbs,
                  ariaLabel: _vm.learnString("classesAndAssignmentsLabel"),
                },
              }),
              _c(
                "section",
                { staticClass: "lesson-details" },
                [
                  _c(
                    "div",
                    [
                      _c("ContentIcon", {
                        staticClass: "lesson-icon",
                        attrs: { kind: "lesson" },
                      }),
                      _c(
                        "h1",
                        { staticClass: "title", attrs: { dir: "auto" } },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.currentLesson.title) +
                              "\n          "
                          ),
                          _vm.lessonHasResources
                            ? _c("ProgressIcon", {
                                staticClass: "progress-icon",
                                attrs: { progress: _vm.lessonProgress },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.currentLesson.description !== ""
                    ? _c("div", [
                        _c("h3", [_vm._v(_vm._s(_vm.$tr("teacherNote")))]),
                        _c("p", { attrs: { dir: "auto" } }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.currentLesson.description) +
                              "\n        "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.lessonResources.length > _vm.contentNodes.length
                    ? _c("ResourceSyncingUiAlert")
                    : _vm._e(),
                ],
                1
              ),
              _vm.lessonHasResources
                ? _c(
                    "section",
                    { staticClass: "content-cards" },
                    _vm._l(_vm.contentNodes, function (content) {
                      return _c("HybridLearningLessonCard", {
                        key: content.id,
                        staticClass: "content-card",
                        attrs: {
                          content: content,
                          isMobile: _vm.windowIsSmall,
                          link: _vm.genContentLinkBackLinkCurrentPage(
                            content.id,
                            true
                          ),
                        },
                      })
                    }),
                    1
                  )
                : _c("p", { staticClass: "no-resources-message" }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$tr("noResourcesInLesson")) +
                        "\n    "
                    ),
                  ]),
            ],
            1
          )
        : _c("KCircularLoader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }