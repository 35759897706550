var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      class: ["completion-modal-section", _vm.$computedClass(_vm.sectionStyle)],
    },
    [
      _c(
        "KFixedGrid",
        { attrs: { numCols: _vm.gridColumnsTotal } },
        [
          _vm.displayGridIcon
            ? _c(
                "KFixedGridItem",
                {
                  staticClass: "icon-wrapper",
                  attrs: { span: _vm.gridIconSpan },
                },
                [
                  _c("KIcon", {
                    staticClass: "icon",
                    attrs: { icon: _vm.icon },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("KFixedGridItem", { attrs: { span: _vm.gridDescriptionSpan } }, [
            _c("h2", { staticClass: "title" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
            ]),
            _c("p", { staticClass: "description" }, [
              _vm._v("\n        " + _vm._s(_vm.description) + "\n      "),
            ]),
          ]),
          _vm.hasButton && _vm.displayGridButton
            ? _c(
                "KFixedGridItem",
                {
                  staticClass: "grid-button-wrapper",
                  attrs: { span: _vm.gridButtonSpan },
                },
                [
                  _c(_vm.buttonRoute ? "KRouterLink" : "KButton", {
                    ref: "button",
                    tag: "component",
                    staticClass: "grid-button",
                    attrs: {
                      to: _vm.buttonRoute,
                      text: _vm.buttonLabel,
                      primary: true,
                      appearance: "flat-button",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("buttonClick")
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "KFixedGrid",
        { staticClass: "content", attrs: { numCols: _vm.gridColumnsTotal } },
        [
          _vm.displayGridIcon
            ? _c("KFixedGridItem", { attrs: { span: 1 } })
            : _vm._e(),
          _c(
            "KFixedGridItem",
            { attrs: { span: _vm.gridContentSpan } },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
      _vm.hasButton && !_vm.displayGridButton
        ? _c(
            "div",
            {
              style: {
                textAlign: _vm.isRtl ? "left" : "right",
                marginTop: "28px",
              },
            },
            [
              _c(_vm.buttonRoute ? "KRouterLink" : "KButton", {
                ref: "button",
                tag: "component",
                attrs: {
                  to: _vm.buttonRoute,
                  text: _vm.buttonLabel,
                  primary: true,
                  appearance: "flat-button",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("buttonClick")
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }