var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Thumbnail", {
    attrs: { thumbnailUrl: _vm.thumbnailUrl, rounded: _vm.rounded },
    scopedSlots: _vm._u(
      [
        {
          key: "icon",
          fn: function () {
            return [
              _vm.contentNode.is_leaf
                ? _c("LearningActivityIcon", {
                    attrs: { kind: _vm.contentNode.learning_activities },
                  })
                : _c("KIcon", {
                    attrs: {
                      icon: "topic",
                      color: _vm.$themePalette.grey.v_600,
                    },
                  }),
            ]
          },
          proxy: true,
        },
        {
          key: "labels",
          fn: function () {
            return [_vm._t("labels")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }