var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "card-main-wrapper", style: _vm.cardStyle },
      [
        _vm.device !== null
          ? _c(
              "KRouterLink",
              {
                staticStyle: { "text-decoration": "none", width: "100%" },
                attrs: { text: _vm.deviceName, to: _vm.routeTo },
              },
              [
                _c(
                  "div",
                  { staticClass: "card-main-body" },
                  [
                    !_vm.viewAll
                      ? _c("KIcon", {
                          staticClass: "icon",
                          attrs: { icon: _vm.getDeviceIcon },
                        })
                      : _vm._e(),
                    !_vm.viewAll
                      ? _c(
                          "div",
                          { staticClass: "device-details" },
                          [
                            _c("TextTruncatorCss", {
                              staticClass: "name",
                              style: { color: _vm.$themePalette.black },
                              attrs: { text: _vm.deviceName, maxLines: 2 },
                            }),
                            _c(
                              "p",
                              {
                                staticClass: "channels",
                                style: { color: _vm.channelColor },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$tr("channels", {
                                        count: _vm.channelCount,
                                      })
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.viewAll
                      ? _c(
                          "div",
                          { staticClass: "name view-all" },
                          [
                            _c("TextTruncatorCss", {
                              attrs: {
                                text: _vm.coreString("viewAll"),
                                maxLines: 1,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }