var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "iframe-container" },
    [
      _c("iframe", {
        ref: "iframe",
        staticClass: "iframe",
        style: { backgroundColor: _vm.$themePalette.grey.v_100 },
        attrs: {
          sandbox: "allow-scripts allow-same-origin",
          frameBorder: "0",
          src: _vm.rooturl,
        },
      }),
      _vm.overlayIsOpen
        ? _c("ContentModal", {
            key: _vm.currentContent.id,
            attrs: {
              contentNode: _vm.currentContent,
              channelTheme: _vm.channelTheme,
            },
            on: {
              close: function ($event) {
                _vm.overlayIsOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }