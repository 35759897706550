var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LearnAppBarPage",
    { attrs: { appBarTitle: _vm.learnString("learnLabel") } },
    [
      _c("KBreadcrumbs", {
        attrs: {
          items: _vm.breadcrumbs,
          ariaLabel: _vm.learnString("classesAndAssignmentsLabel"),
        },
      }),
      _vm.isUserLoggedIn
        ? _c("YourClasses", {
            attrs: { classes: _vm.classrooms, loading: _vm.loading },
          })
        : _c("AuthMessage", { attrs: { authorizedRole: "learner" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }