var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "NotificationsRoot",
    {
      attrs: {
        authorized: _vm.userIsAuthorized,
        authorizedRole: "registeredUser",
      },
    },
    [_c("router-view", { attrs: { loading: _vm.loading } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }