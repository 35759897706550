var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "metadata" },
    [
      _c("div", { staticClass: "flex section" }, [
        _c("div", [
          _vm.forBeginners
            ? _c(
                "span",
                {
                  staticClass: "beginners-chip",
                  class: _vm.$computedClass(_vm.chipStyle),
                  attrs: { "data-test": "beginners-chip" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.coreString("forBeginners")) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          [
            _c("KRouterLink", {
              ref: "resourceButton",
              attrs: {
                text: _vm.learnString("viewResource"),
                appearance: "raised-button",
                primary: false,
                to: _vm.genContentLinkKeepCurrentBackLink(
                  _vm.content.id,
                  _vm.content.is_leaf
                ),
                "data-test": "view-resource-link",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "section" },
        [_c("ContentNodeThumbnail", { attrs: { contentNode: _vm.content } })],
        1
      ),
      _vm.content.title
        ? _c(
            "div",
            {
              staticClass: "section title",
              attrs: { "data-test": "content-title" },
            },
            [_vm._v("\n    " + _vm._s(_vm.content.title) + "\n  ")]
          )
        : _vm._e(),
      _vm.content.description
        ? _c(
            "div",
            {
              ref: "description",
              staticClass: "content",
              class: _vm.truncate,
              attrs: { "data-test": "content-description" },
            },
            [_vm._v("\n    " + _vm._s(_vm.content.description) + "\n  ")]
          )
        : _vm._e(),
      _vm.descriptionOverflow
        ? _c("KButton", {
            staticClass: "show-more-button",
            attrs: {
              text: _vm.showMoreOrLess,
              appearance: "basic-link",
              "data-test": "show-more-or-less",
              primary: true,
            },
            on: { click: _vm.toggleShowMoreOrLess },
          })
        : _c("div", { staticClass: "section" }),
      _vm.content.duration
        ? _c(
            "div",
            {
              staticClass: "section",
              attrs: { "data-test": "estimated-time" },
            },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.learnString("estimatedTime")) +
                    ":\n    "
                ),
              ]),
              _c(
                "span",
                [
                  _c("TimeDuration", {
                    attrs: { seconds: _vm.content.duration },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.content.grade_levels && _vm.content.grade_levels.length
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "grade-levels" } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.coreString("levelLabel")) + ":\n    "
                ),
              ]),
              _c("span", [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.levels(_vm.content.grade_levels)) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.content.lang
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "lang" } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.coreString("languageLabel")) +
                    ":\n    "
                ),
              ]),
              _c("span", [
                _vm._v(
                  "\n      " + _vm._s(_vm.content.lang.lang_name) + "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.accessibilityLabels
        ? _c(
            "div",
            {
              staticClass: "section",
              attrs: { "data-test": "accessibility-labels" },
            },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.coreString("accessibility")) +
                    ":\n    "
                ),
              ]),
              _c("span", [
                _vm._v("\n      " + _vm._s(_vm.accessibilityLabels) + "\n    "),
              ]),
            ]
          )
        : _vm._e(),
      _vm.content.learner_needs
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "learner-needs" } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.learnString("whatYouWillNeed")) +
                    ":\n    "
                ),
              ]),
              _c("span", [
                _vm._v("\n      " + _vm._s(_vm.learnerNeedsLabels) + "\n    "),
              ]),
            ]
          )
        : _vm._e(),
      _vm.content.author
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "author" } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.learnString("author")) + ":\n    "
                ),
              ]),
              _c("span", [
                _vm._v("\n      " + _vm._s(_vm.content.author) + "\n    "),
              ]),
            ]
          )
        : _vm._e(),
      _vm.content.license_owner
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "license-owner" } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.learnString("copyrightHolder")) +
                    ":\n    "
                ),
              ]),
              _c("span", [
                _vm._v(
                  "\n      " + _vm._s(_vm.content.license_owner) + "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm.licenseDescription
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "license-desc" } },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.learnString("license")) + ":\n    "
                ),
              ]),
              _c(
                "span",
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.licenseShortName || "") + "\n      "
                  ),
                  _c("KIconButton", {
                    staticClass: "absolute-icon license-toggle",
                    attrs: {
                      icon: _vm.licenseDescriptionIsVisible
                        ? "chevronUp"
                        : "chevronDown",
                      ariaLabel: _vm.learnString("toggleLicenseDescription"),
                      size: "small",
                      type: "secondary",
                    },
                    on: {
                      click: function ($event) {
                        _vm.licenseDescriptionIsVisible =
                          !_vm.licenseDescriptionIsVisible
                      },
                    },
                  }),
                  _vm.licenseDescriptionIsVisible
                    ? _c("div", { staticClass: "license-details" }, [
                        _c("p", { staticClass: "license-details-name" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.licenseLongName) +
                              "\n        "
                          ),
                        ]),
                        _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                          _vm._v(_vm._s(_vm.licenseDescription)),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.recommendations
        ? _c(
            "div",
            {
              staticClass: "related section",
              attrs: { "data-test": "recommendations" },
            },
            [
              _c("div", { staticClass: "label" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.coreString("related")) + ":\n    "
                ),
              ]),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(_vm.recommendations, function (related) {
                  return _c(
                    "div",
                    { key: related.title, staticClass: "list-item" },
                    [
                      _c(
                        "KRouterLink",
                        {
                          attrs: {
                            to: _vm.genContentLinkKeepCurrentBackLink(
                              related.id,
                              related.is_leaf
                            ),
                          },
                        },
                        [
                          _c(
                            "KLabeledIcon",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "icon",
                                    fn: function () {
                                      return [
                                        _c("LearningActivityIcon", {
                                          attrs: {
                                            kind: related.learning_activities,
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(related.title) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ]
          )
        : _vm._e(),
      _vm.showLocationsInChannel && _vm.locationsInChannel
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "locations" } },
            [
              _c("div", { staticClass: "label" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.learnString("locationsInChannel", {
                        channelname: (_vm.content.ancestors[0] || {}).title,
                      })
                    ) +
                    ":\n    "
                ),
              ]),
              _vm._l(_vm.locationsInChannel, function (location) {
                return _c("div", { key: location.id }, [
                  _c(
                    "div",
                    [
                      _c(
                        "KRouterLink",
                        {
                          attrs: {
                            to: _vm.genContentLinkKeepCurrentBackLink(
                              _vm.lastAncestor(location).id,
                              false
                            ),
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.lastAncestor(location).title) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              }),
            ],
            2
          )
        : _vm._e(),
      _vm.canDownloadExternally
        ? _c(
            "div",
            { staticClass: "section", attrs: { "data-test": "download" } },
            [
              _c("DownloadButton", {
                attrs: {
                  files: _vm.content.files,
                  nodeTitle: _vm.content.title,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }