var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card drop-shadow" },
    [
      _c(
        "router-link",
        {
          staticClass: "card card-content",
          class: [
            { "mobile-card": _vm.isMobile },
            _vm.$computedClass({ ":focus": _vm.$coreOutline }),
          ],
          style: { backgroundColor: _vm.$themeTokens.surface },
          attrs: { to: _vm.link },
        },
        [
          _c(
            "div",
            { staticClass: "thumbnail" },
            [
              _c("CardThumbnail", {
                attrs: {
                  isMobile: _vm.isMobile,
                  contentNode: _vm.content,
                  hideDuration: true,
                },
              }),
            ],
            1
          ),
          _c(
            "h3",
            { staticClass: "title" },
            [
              _c("TextTruncatorCss", {
                style: { color: _vm.$themeTokens.text },
                attrs: { text: _vm.content.title, maxLines: 1 },
              }),
            ],
            1
          ),
          _c("LearningActivityLabel", {
            staticClass: "learning-activity-label",
            style: { color: _vm.$themeTokens.text },
            attrs: { contentNode: _vm.content },
          }),
          _c(
            "div",
            { staticClass: "footer" },
            [_c("ProgressBar", { attrs: { contentNode: _vm.content } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }