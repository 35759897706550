var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Backdrop",
    {
      staticClass: "backdrop",
      style: _vm.backdropStyle,
      attrs: { transitions: true },
    },
    [
      _c("div", { staticClass: "renderer", style: _vm.bodyStyle }, [
        _c(
          "nav",
          [
            _c(
              "UiToolbar",
              {
                ref: "toolbar",
                style: _vm.toolbarStyle,
                attrs: { showIcon: true },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("KIconButton", {
                          attrs: {
                            icon: "close",
                            color: _vm.getThemeToken("textColor"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              },
              [
                _c("span", { style: _vm.titleStyle }, [
                  _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "main",
          [_c("ContentItem", { attrs: { contentNode: _vm.contentNode } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }