var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("MissingResourceAlert", {
    attrs: { multiple: _vm.multiple },
    scopedSlots: _vm._u(
      [
        _vm.isLearnerOnlyImport && _vm.isSyncing
          ? {
              key: "syncAlert",
              fn: function () {
                return [_vm._v("\n    " + _vm._s(_vm.syncMessage) + "\n  ")]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }