var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "KFixedGrid",
        { attrs: { numCols: 3 } },
        [
          _c("KFixedGridItem", { attrs: { span: 2 } }, [
            _c(
              "h2",
              { style: { marginTop: 0 } },
              [
                _c("KLabeledIcon", {
                  attrs: {
                    icon: "classes",
                    label: _vm.$tr("yourClassesHeader"),
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "KFixedGridItem",
            { attrs: { span: 1, alignment: "right" } },
            [
              _vm.displayAllClassesLink
                ? _c("KRouterLink", {
                    attrs: {
                      text: _vm.coreString("viewAll"),
                      to: _vm.allClassesLink,
                      "data-test": "viewAllLink",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.classes && _vm.classes.length > 0
        ? _c(
            "CardGrid",
            { attrs: { gridType: 2 } },
            _vm._l(_vm.visibleClasses, function (c) {
              return _c(
                "CardLink",
                {
                  key: c.id,
                  attrs: {
                    "data-test": "classLink",
                    to: _vm.classAssignmentsLink(c.id),
                  },
                },
                [
                  _c(
                    "h3",
                    {
                      style: { margin: 0, fontWeight: "normal" },
                      attrs: { dir: "auto" },
                    },
                    [_vm._v("\n        " + _vm._s(c.name) + "\n      ")]
                  ),
                ]
              )
            }),
            1
          )
        : _vm.loading
        ? _c("KCircularLoader")
        : !_vm.loading
        ? _c("p", [_vm._v("\n    " + _vm._s(_vm.$tr("noClasses")) + "\n  ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }