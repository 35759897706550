var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ImmersivePage",
    { attrs: { route: _vm.homePageLink, appBarTitle: _vm.exam.title || "" } },
    [
      _vm.loading
        ? _c("KCircularLoader")
        : _c(
            "KGrid",
            { attrs: { gridStyle: _vm.gridStyle } },
            [
              _vm.windowIsLarge
                ? _c(
                    "KGridItem",
                    {
                      ref: "questionListWrapper",
                      staticClass: "column-pane",
                      attrs: { layout12: { span: 4 } },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "column-contents-wrapper" },
                        [
                          _c(
                            "KPageContainer",
                            [
                              _c(
                                "div",
                                [
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(_vm.coreString("timeSpentLabel"))
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { style: { paddingBottom: "8px" } },
                                    [
                                      _c("TimeDuration", {
                                        staticClass: "timer",
                                        attrs: { seconds: _vm.time_spent },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.content && _vm.content.duration
                                    ? _c("p", [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.learnString("suggestedTime")
                                            ) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.content && _vm.content.duration
                                    ? _c("SuggestedTime", {
                                        staticClass: "timer",
                                        attrs: {
                                          seconds: _vm.content.duration,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("span", {
                                staticClass: "divider",
                                style: {
                                  borderTop:
                                    "solid 1px " + _vm.$themeTokens.fineLine,
                                },
                              }),
                              _c("AnswerHistory", {
                                attrs: {
                                  pastattempts: _vm.pastattempts,
                                  questions: _vm.questions,
                                  questionNumber: _vm.questionNumber,
                                  wrapperComponentRefs: this.$refs,
                                },
                                on: { goToQuestion: _vm.goToQuestion },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "KGridItem",
                {
                  staticClass: "column-pane",
                  attrs: { layout12: { span: 8 } },
                },
                [
                  _c(
                    "main",
                    {
                      class: { "column-contents-wrapper": !_vm.windowIsSmall },
                    },
                    [
                      _c(
                        "KPageContainer",
                        [
                          _c("h1", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$tr("question", {
                                    num: _vm.questionNumber + 1,
                                    total: _vm.exam.question_count,
                                  })
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm.content && _vm.itemId
                            ? _c("KContentRenderer", {
                                ref: "contentRenderer",
                                attrs: {
                                  kind: _vm.content.kind,
                                  files: _vm.content.files,
                                  available: _vm.content.available,
                                  extraFields: _vm.content.extra_fields,
                                  itemId: _vm.itemId,
                                  assessment: true,
                                  allowHints: false,
                                  answerState: _vm.currentAttempt.answer,
                                },
                                on: { interaction: _vm.saveAnswer },
                              })
                            : _c("ResourceSyncingUiAlert", {
                                attrs: { multiple: false },
                              }),
                        ],
                        1
                      ),
                      _c(
                        "BottomAppBar",
                        {
                          attrs: {
                            dir: _vm.bottomBarLayoutDirection,
                            maxWidth: null,
                          },
                        },
                        [
                          _c(
                            _vm.windowIsSmall ? "div" : "KButtonGroup",
                            { tag: "component" },
                            [
                              _c(
                                "KButton",
                                {
                                  attrs: {
                                    disabled:
                                      _vm.questionNumber ===
                                      _vm.exam.question_count - 1,
                                    primary: true,
                                    dir: _vm.layoutDirReset,
                                    "aria-label": _vm.$tr("nextQuestion"),
                                    appearanceOverrides:
                                      _vm.navigationButtonStyle,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToQuestion(
                                        _vm.questionNumber + 1
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "iconAfter",
                                      fn: function () {
                                        return [
                                          _c("KIcon", {
                                            style: _vm.navigationIconStyleNext,
                                            attrs: {
                                              icon: "forward",
                                              color:
                                                _vm.$themeTokens.textInverted,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm.displayNavigationButtonLabel
                                    ? _c("span", [
                                        _vm._v(_vm._s(_vm.$tr("nextQuestion"))),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                "KButton",
                                {
                                  class: { "left-align": _vm.windowIsSmall },
                                  attrs: {
                                    disabled: _vm.questionNumber === 0,
                                    primary: true,
                                    dir: _vm.layoutDirReset,
                                    appearanceOverrides:
                                      _vm.navigationButtonStyle,
                                    "aria-label": _vm.$tr("previousQuestion"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goToQuestion(
                                        _vm.questionNumber - 1
                                      )
                                    },
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "icon",
                                      fn: function () {
                                        return [
                                          _c("KIcon", {
                                            style:
                                              _vm.navigationIconStylePrevious,
                                            attrs: {
                                              icon: "back",
                                              color:
                                                _vm.$themeTokens.textInverted,
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _vm.displayNavigationButtonLabel
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$tr("previousQuestion"))
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm.windowIsLarge
                            ? _c(
                                "div",
                                {
                                  staticClass: "left-align",
                                  attrs: { dir: _vm.layoutDirReset },
                                },
                                [
                                  !_vm.missingResources
                                    ? _c("div", { staticClass: "answered" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.answeredText) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                  !_vm.missingResources
                                    ? _c("KButton", {
                                        attrs: {
                                          text: _vm.$tr("submitExam"),
                                          primary: false,
                                          appearance: "flat-button",
                                        },
                                        on: { click: _vm.toggleModal },
                                      })
                                    : _vm._e(),
                                  _vm.missingResources
                                    ? _c("div", { staticClass: "nosubmit" }, [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.$tr("unableToSubmit")) +
                                            "\n            "
                                        ),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      !_vm.windowIsLarge
                        ? _c("KPageContainer", [
                            _c(
                              "div",
                              {
                                staticClass: "bottom-block",
                                class: { windowIsSmall: _vm.windowIsSmall },
                              },
                              [
                                !_vm.missingResources
                                  ? _c("div", { staticClass: "answered" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.answeredText) +
                                          "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                                !_vm.missingResources
                                  ? _c("KButton", {
                                      attrs: {
                                        text: _vm.$tr("submitExam"),
                                        primary: false,
                                        appearance: "flat-button",
                                      },
                                      on: { click: _vm.toggleModal },
                                    })
                                  : _vm._e(),
                                _vm.missingResources
                                  ? _c("div", { staticClass: "nosubmit" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$tr("unableToSubmit")) +
                                          "\n            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
      _vm.submitModalOpen
        ? _c(
            "KModal",
            {
              attrs: {
                title: _vm.$tr("submitExam"),
                submitText: _vm.$tr("submitExam"),
                cancelText: _vm.coreString("goBackAction"),
              },
              on: { submit: _vm.finishExam, cancel: _vm.toggleModal },
            },
            [
              _c("p", [_vm._v(_vm._s(_vm.$tr("areYouSure")))]),
              _vm.questionsUnanswered
                ? _c("p", [
                    _vm._v(
                      "\n      " +
                        _vm._s(
                          _vm.$tr("unanswered", {
                            numLeft: _vm.questionsUnanswered,
                          })
                        ) +
                        "\n    "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }