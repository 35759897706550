var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("LessonMasteryBar", {
        attrs: { requiredCorrectAnswers: _vm.totalCorrectRequiredM },
        scopedSlots: _vm._u([
          {
            key: "hint",
            fn: function () {
              return [
                _vm.totalHints > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "hint-btn-container",
                        class: { rtl: _vm.isRtl },
                      },
                      [
                        _vm.availableHints > 0
                          ? _c("KButton", {
                              staticClass: "hint-btn",
                              attrs: {
                                appearance: "basic-link",
                                text: _vm.hint$tr("hint", {
                                  hintsLeft: _vm.availableHints,
                                }),
                                primary: false,
                              },
                              on: { click: _vm.takeHint },
                            })
                          : _c("KButton", {
                              staticClass: "hint-btn",
                              attrs: {
                                appearance: "basic-link",
                                text: _vm.hint$tr("noMoreHint"),
                                primary: false,
                                disabled: true,
                              },
                            }),
                        _c("CoreInfoIcon", {
                          staticClass: "info-icon",
                          attrs: {
                            tooltipPlacement: "bottom left",
                            iconAriaLabel: _vm.hint$tr("hintExplanation"),
                            tooltipText: _vm.hint$tr("hintExplanation"),
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "div",
        [
          _vm.itemError
            ? _c(
                "UiAlert",
                { attrs: { dismissible: false, type: "error" } },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$tr("itemError")) + "\n      "
                  ),
                  _c("KButton", {
                    attrs: {
                      appearance: "basic-link",
                      text: _vm.$tr("tryDifferentQuestion"),
                    },
                    on: { click: _vm.nextQuestion },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "content-wrapper",
              style: { backgroundColor: this.$themePalette.grey.v_50 },
            },
            [
              _c("KContentRenderer", {
                ref: "contentRenderer",
                attrs: {
                  kind: _vm.kind,
                  lang: _vm.lang,
                  files: _vm.files,
                  available: _vm.available,
                  extraFields: _vm.extraFields,
                  assessment: true,
                  itemId: _vm.itemId,
                  progress: _vm.progress,
                  userId: _vm.userId,
                  userFullName: _vm.userFullName,
                  timeSpent: _vm.timeSpent,
                },
                on: {
                  answerGiven: _vm.answerGiven,
                  hintTaken: _vm.hintTaken,
                  itemError: _vm.handleItemError,
                  startTracking: _vm.startTracking,
                  stopTracking: _vm.stopTracking,
                  updateProgress: _vm.updateProgress,
                  updateContentState: _vm.updateContentState,
                  error: function (err) {
                    return _vm.$emit("error", err)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "BottomAppBar",
            {
              staticClass: "attempts-container",
              class: { mobile: _vm.windowIsSmall },
            },
            [
              _c(
                "div",
                {
                  staticClass: "overall-status",
                  style: { color: _vm.$themeTokens.text },
                },
                [
                  _c("KIcon", {
                    attrs: {
                      icon: "mastered",
                      color: _vm.success
                        ? _vm.$themeTokens.mastered
                        : _vm.$themePalette.grey.v_200,
                    },
                  }),
                  _c("div", { staticClass: "overall-status-text" }, [
                    _vm.success
                      ? _c(
                          "span",
                          {
                            staticClass: "completed",
                            style: { color: _vm.$themeTokens.annotation },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.coreString("completedLabel")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("span", [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$tr("goal", {
                              count: _vm.totalCorrectRequiredM,
                            })
                          ) +
                          "\n          "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "table" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "left" },
                    [
                      _c(
                        "transition",
                        { attrs: { mode: "out-in" } },
                        [
                          !_vm.complete
                            ? _c("KButton", {
                                class: { shaking: _vm.shake },
                                attrs: {
                                  appearance: "raised-button",
                                  text: _vm.$tr("check"),
                                  primary: true,
                                  disabled: _vm.checkingAnswer,
                                },
                                on: { click: _vm.checkAnswer },
                              })
                            : _c("KButton", {
                                attrs: {
                                  appearance: "raised-button",
                                  text: _vm.$tr("next"),
                                  primary: true,
                                },
                                on: { click: _vm.nextQuestion },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      _c("ExerciseAttempts", {
                        attrs: {
                          waitingForAttempt:
                            _vm.firstAttemptAtQuestion || _vm.itemError,
                          numSpaces: _vm.attemptsWindowN,
                          log: _vm.recentAttempts,
                        },
                      }),
                      _c("p", { staticClass: "current-status" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.currentStatus) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }