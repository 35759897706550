var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentChannelIsCustom
        ? _c(
            "div",
            [_c("CustomContentRenderer", { attrs: { topic: _vm.topic } })],
            1
          )
        : _c(
            "ImmersivePage",
            {
              staticClass: "page",
              attrs: {
                route: _vm.back,
                appBarTitle: _vm.barTitle,
                appearanceOverrides: {},
                primary: false,
              },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _vm.deviceId
                        ? _c("DeviceConnectionStatus", {
                            attrs: { deviceId: _vm.deviceId, color: "white" },
                          })
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm.loading
                ? _c("KCircularLoader", { staticClass: "page-loader" })
                : _c(
                    "div",
                    [
                      !_vm.windowIsSmall
                        ? _c("TopicsHeader", {
                            ref: "header",
                            attrs: {
                              role: "complementary",
                              "data-test": "header-breadcrumbs",
                              title: (_vm.topic && _vm.topic.title) || "",
                              description: _vm.topic && _vm.topic.description,
                              thumbnail: _vm.topic && _vm.topic.thumbnail,
                              breadcrumbs: _vm.breadcrumbs,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "sticky-sidebar",
                                  fn: function () {
                                    return [
                                      !!_vm.windowIsLarge && _vm.topic
                                        ? _c("ToggleHeaderTabs", {
                                            attrs: {
                                              topic: _vm.topic,
                                              topics: _vm.topics,
                                              width: _vm.sidePanelWidth,
                                            },
                                          })
                                        : _vm._e(),
                                      !!_vm.windowIsLarge && _vm.searchActive
                                        ? _c("SearchFiltersPanel", {
                                            ref: "sidePanel",
                                            staticClass: "side-panel",
                                            style: _vm.sidePanelStyleOverrides,
                                            attrs: {
                                              width: _vm.sidePanelWidth + "px",
                                              showChannels: false,
                                            },
                                            model: {
                                              value: _vm.searchTerms,
                                              callback: function ($$v) {
                                                _vm.searchTerms = $$v
                                              },
                                              expression: "searchTerms",
                                            },
                                          })
                                        : !!_vm.windowIsLarge
                                        ? _c("TopicsPanelModal", {
                                            ref: "sidePanel",
                                            staticClass: "side-panel",
                                            style: _vm.sidePanelStyleOverrides,
                                            attrs: {
                                              topics: _vm.topics,
                                              topicMore: Boolean(_vm.topicMore),
                                              topicsLoading:
                                                _vm.topicMoreLoading,
                                              width: _vm.sidePanelWidth + "px",
                                            },
                                            on: {
                                              loadMoreTopics:
                                                _vm.handleLoadMoreInTopic,
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              505773851
                            ),
                          })
                        : _c("TopicsMobileHeader", {
                            attrs: { topic: _vm.topic },
                          }),
                      _c(
                        "main",
                        {
                          staticClass: "main-content-grid",
                          style: _vm.gridStyle,
                        },
                        [
                          _vm.breadcrumbs.length && _vm.windowIsSmall
                            ? _c("KBreadcrumbs", {
                                attrs: {
                                  "data-test": "mobile-breadcrumbs",
                                  items: _vm.breadcrumbs,
                                  ariaLabel: _vm.learnString(
                                    "channelAndFoldersLabel"
                                  ),
                                },
                              })
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "card-grid" },
                            [
                              !_vm.windowIsLarge
                                ? _c(
                                    "div",
                                    { attrs: { "data-test": "tab-buttons" } },
                                    [
                                      _vm.topics.length
                                        ? _c("KButton", {
                                            staticClass:
                                              "overlay-toggle-button",
                                            attrs: {
                                              icon: "topic",
                                              "data-test": "folders-button",
                                              text: _vm.coreString("folders"),
                                              primary: false,
                                            },
                                            on: {
                                              click: _vm.handleFoldersButton,
                                            },
                                          })
                                        : _vm._e(),
                                      _c("KButton", {
                                        staticClass: "overlay-toggle-button",
                                        attrs: {
                                          icon: "filter",
                                          "data-test": "filter-button",
                                          text: _vm.coreString("filter"),
                                          primary: false,
                                        },
                                        on: { click: _vm.handleSearchButton },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              !_vm.displayingSearchResults
                                ? _c(
                                    "div",
                                    { attrs: { "data-test": "topics" } },
                                    [
                                      _vm._l(
                                        _vm.topicsForDisplay,
                                        function (t) {
                                          return [
                                            _c("TopicSubsection", {
                                              key: t.id,
                                              attrs: {
                                                topic: t,
                                                subTopicLoading:
                                                  t.id === _vm.subTopicLoading,
                                                gridType: _vm.gridType,
                                                allowDownloads:
                                                  _vm.allowDownloads,
                                              },
                                              on: {
                                                showMore: _vm.handleShowMore,
                                                loadMoreInSubtopic:
                                                  _vm.handleLoadMoreInSubtopic,
                                                toggleInfoPanel:
                                                  _vm.toggleInfoPanel,
                                              },
                                            }),
                                          ]
                                        }
                                      ),
                                      _vm.resources.length
                                        ? _c(
                                            "LibraryAndChannelBrowserMainContent",
                                            {
                                              attrs: {
                                                allowDownloads:
                                                  _vm.allowDownloads,
                                                "data-test": "search-results",
                                                contents:
                                                  _vm.resourcesDisplayed,
                                                gridType: _vm.gridType,
                                                currentCardViewStyle: "card",
                                              },
                                              on: {
                                                toggleInfoPanel:
                                                  _vm.toggleInfoPanel,
                                              },
                                            }
                                          )
                                        : _vm._e(),
                                      _vm.moreResources
                                        ? _c(
                                            "KButton",
                                            {
                                              staticClass: "more-after-grid",
                                              attrs: {
                                                appearance: "basic-link",
                                              },
                                              on: {
                                                click:
                                                  _vm.handleShowMoreResources,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.coreString(
                                                      "showMoreAction"
                                                    )
                                                  ) +
                                                  "\n            "
                                              ),
                                            ]
                                          )
                                        : _vm.topicMore
                                        ? _c(
                                            "div",
                                            { staticClass: "end-button-block" },
                                            [
                                              !_vm.topicMoreLoading
                                                ? _c("KButton", {
                                                    attrs: {
                                                      text: _vm.coreString(
                                                        "viewMoreAction"
                                                      ),
                                                      appearance:
                                                        "raised-button",
                                                      disabled:
                                                        _vm.topicMoreLoading,
                                                    },
                                                    on: {
                                                      click:
                                                        _vm.handleLoadMoreInTopic,
                                                    },
                                                  })
                                                : _c("KCircularLoader"),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  )
                                : _c("SearchResultsGrid", {
                                    attrs: {
                                      "data-test": "search-results",
                                      allowDownloads: _vm.allowDownloads,
                                      currentCardViewStyle:
                                        _vm.currentSearchCardViewStyle,
                                      hideCardViewToggle: true,
                                      results: _vm.results,
                                      removeFilterTag: _vm.removeFilterTag,
                                      clearSearch: _vm.clearSearch,
                                      moreLoading: _vm.moreLoading,
                                      searchMore: _vm.searchMore,
                                      searchTerms: _vm.searchTerms,
                                      searchLoading: _vm.searchLoading,
                                      more: _vm.more,
                                    },
                                    on: {
                                      setCardStyle: function (style) {
                                        return (_vm.currentSearchCardViewStyle =
                                          style)
                                      },
                                      setSidePanelMetadataContent: function (
                                        content
                                      ) {
                                        return (_vm.metadataSidePanelContent =
                                          content)
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.windowIsLarge && _vm.sidePanelIsOpen
                        ? [
                            _vm.searchActive
                              ? _c("SearchFiltersPanel", {
                                  ref: "embeddedPanel",
                                  staticClass: "full-screen-side-panel",
                                  style: _vm.sidePanelStyleOverrides,
                                  attrs: { showChannels: false },
                                  on: {
                                    close: function ($event) {
                                      _vm.sidePanelIsOpen = false
                                    },
                                  },
                                  model: {
                                    value: _vm.searchTerms,
                                    callback: function ($$v) {
                                      _vm.searchTerms = $$v
                                    },
                                    expression: "searchTerms",
                                  },
                                })
                              : _c("TopicsPanelModal", {
                                  ref: "embeddedPanel",
                                  staticClass: "full-screen-side-panel",
                                  style: _vm.sidePanelStyleOverrides,
                                  attrs: {
                                    topics: _vm.topics,
                                    topicMore: Boolean(_vm.topicMore),
                                    topicsLoading: _vm.topicMoreLoading,
                                  },
                                  on: {
                                    loadMoreTopics: _vm.handleLoadMoreInTopic,
                                    close: function ($event) {
                                      _vm.sidePanelIsOpen = false
                                    },
                                  },
                                }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
              _vm.metadataSidePanelContent
                ? _c(
                    "SidePanelModal",
                    {
                      attrs: { alignment: "right" },
                      on: {
                        closePanel: function ($event) {
                          _vm.metadataSidePanelContent = null
                        },
                        shouldFocusFirstEl: function ($event) {
                          return _vm.findFirstEl()
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return _vm._l(
                                _vm.metadataSidePanelContent
                                  .learning_activities,
                                function (activity) {
                                  return _c(
                                    "div",
                                    {
                                      key: activity,
                                      staticClass: "side-panel-chips",
                                      class: _vm.$computedClass({
                                        "::after": {
                                          content: "",
                                          flex: "auto",
                                        },
                                      }),
                                    },
                                    [
                                      _c("LearningActivityChip", {
                                        staticClass: "chip",
                                        staticStyle: {
                                          "margin-left": "8px",
                                          "margin-bottom": "8px",
                                        },
                                        attrs: { kind: activity },
                                      }),
                                    ],
                                    1
                                  )
                                }
                              )
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        3724738688
                      ),
                    },
                    [
                      _c("BrowseResourceMetadata", {
                        ref: "resourcePanel",
                        attrs: {
                          content: _vm.metadataSidePanelContent,
                          showLocationsInChannel: true,
                          canDownloadExternally:
                            _vm.canDownloadExternally && !_vm.deviceId,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }