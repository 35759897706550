var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "results-header-group" },
    [
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.key + item.value,
            staticClass: "filter-chip",
            style: { backgroundColor: _vm.$themePalette.grey.v_100 },
          },
          [
            _c(
              "span",
              [
                _c("p", { staticClass: "filter-chip-text" }, [
                  _vm._v(_vm._s(item.text)),
                ]),
                _c("KIconButton", {
                  staticClass: "filter-chip-button",
                  attrs: { icon: "delete", size: "mini" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("removeItem", item)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _c("KButton", {
        staticClass: "filter-action-button",
        attrs: {
          text: _vm.coreString("clearAllAction"),
          appearance: "basic-link",
        },
        on: {
          click: function ($event) {
            return _vm.$emit("clearSearch")
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }