var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "KFixedGrid",
        { attrs: { numCols: 3 } },
        [
          _c("KFixedGridItem", { attrs: { span: 2 } }, [
            _c(
              "h2",
              { style: { marginTop: 0 } },
              [
                _c("KLabeledIcon", {
                  attrs: { icon: "channel", label: _vm.$tr("header") },
                }),
              ],
              1
            ),
          ]),
          _c(
            "KFixedGridItem",
            { attrs: { span: 1, alignment: "right" } },
            [
              _vm.displayAllChannelsLink
                ? _c("KRouterLink", {
                    attrs: {
                      text: _vm.coreString("viewAll"),
                      to: _vm.allChannelsLink,
                      "data-test": "viewAllLink",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "CardGrid",
        { attrs: { gridType: 1 } },
        _vm._l(_vm.visibleChannels, function (channel, idx) {
          return _c("BaseChannelCard", {
            key: idx,
            attrs: {
              "data-test": "channelLink",
              channel: channel,
              to: _vm.getChannelLink(channel),
            },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }