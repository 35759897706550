var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.windowIsLarge ? "div" : "SidePanelModal",
    {
      tag: "component",
      class: _vm.windowIsLarge ? "side-panel" : "",
      style: _vm.windowIsLarge
        ? {
            color: _vm.$themeTokens.text,
            backgroundColor: _vm.$themeTokens.surface,
            width: _vm.width,
          }
        : {},
      attrs: {
        alignment: "left",
        role: "region",
        "aria-label": _vm.learnString("filterAndSearchLabel"),
        ariaLabel: _vm.learnString("filterAndSearchLabel"),
      },
      on: {
        closePanel: function ($event) {
          return _vm.$emit("close")
        },
        shouldFocusFirstEl: function ($event) {
          return _vm.focusFirstEl()
        },
      },
    },
    [
      _c(
        "div",
        { class: _vm.windowIsLarge ? "" : "drawer-panel" },
        [
          _vm._l(_vm.topics || [], function (t) {
            return _c(
              "div",
              { key: t.id },
              [
                _c("KRouterLink", {
                  ref: "folders",
                  refInFor: true,
                  staticClass: "side-panel-folder-link",
                  attrs: {
                    text: t.title,
                    appearanceOverrides: { color: _vm.$themeTokens.text },
                    to: _vm.genContentLinkKeepCurrentBackLink(t.id, false),
                  },
                }),
              ],
              1
            )
          }),
          _vm.topicMore && !_vm.topicsLoading
            ? _c(
                "KButton",
                {
                  attrs: { appearance: "basic-link" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("loadMoreTopics")
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.coreString("viewMoreAction")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm.topicsLoading ? _c("KCircularLoader") : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }