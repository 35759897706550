var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "KGrid",
        { staticClass: "grid", attrs: { gutter: "0" } },
        [
          _c(
            "KGridItem",
            {
              attrs: {
                layout12: { span: 6 },
                layout8: { span: 4 },
                layout4: { span: 4 },
              },
            },
            [
              _c("h1", { style: { marginLeft: "-8px" } }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.injectedtr("otherLibraries")) +
                    "\n      "
                ),
              ]),
            ]
          ),
          _c(
            "KGridItem",
            {
              attrs: {
                layout12: { span: 6 },
                layout8: { span: 4 },
                layout4: { span: 4 },
              },
            },
            [
              _c("div", { staticClass: "sync-status" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.searchingOtherLibraries,
                        expression: "searchingOtherLibraries",
                      },
                    ],
                    attrs: { "data-test": "searching" },
                  },
                  [
                    _c("span", { attrs: { "data-test": "searching-label" } }, [
                      _vm._v(_vm._s(_vm.injectedtr("searchingOtherLibrary"))),
                    ]),
                    _vm._v("\n                \n          "),
                    _c(
                      "span",
                      [
                        _c("KCircularLoader", {
                          attrs: { type: "indeterminate", stroke: 6 },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.searchingOtherLibraries &&
                          _vm.devicesWithChannelsExist,
                        expression:
                          "!searchingOtherLibraries && devicesWithChannelsExist",
                      },
                    ],
                    attrs: { "data-test": "showing-all" },
                  },
                  [
                    _c(
                      "span",
                      [
                        _vm.windowIsSmall
                          ? _c("KIcon", {
                              staticClass: "wifi-svg",
                              attrs: { icon: "wifi" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v("\n                \n          "),
                    _c(
                      "span",
                      { attrs: { "data-test": "showing-all-label" } },
                      [_vm._v(_vm._s(_vm.injectedtr("showingAllLibraries")))]
                    ),
                    _vm._v("\n                \n          "),
                    _c(
                      "span",
                      [
                        !_vm.windowIsSmall
                          ? _c("KIcon", {
                              staticClass: "wifi-svg",
                              attrs: { icon: "wifi" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.searchingOtherLibraries &&
                          !_vm.devicesWithChannelsExist,
                        expression:
                          "!searchingOtherLibraries && !devicesWithChannelsExist",
                      },
                    ],
                    attrs: { "data-test": "no-other" },
                  },
                  [
                    _c(
                      "span",
                      [_c("KIcon", { attrs: { icon: "disconnected" } })],
                      1
                    ),
                    _vm._v("\n                \n          "),
                    _c("span", { attrs: { "data-test": "no-other-label" } }, [
                      _vm._v(_vm._s(_vm.injectedtr("noOtherLibraries"))),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      !_vm.threeLibrariesOrFewer &&
      _vm.pinnedDevicesExist &&
      _vm.unpinnedDevicesExist
        ? _c(
            "h2",
            {
              style: { marginLeft: "-8px" },
              attrs: { "data-test": "pinned-label" },
            },
            [_vm._v("\n    " + _vm._s(_vm.injectedtr("pinned")) + "\n  ")]
          )
        : _vm._e(),
      _c(
        "FadeInTransitionGroup",
        { staticClass: "other-libraries-grid" },
        _vm._l(_vm.fullLibrariesToDisplay, function (device) {
          return _c("LibraryItem", {
            key: device["instance_id"],
            attrs: {
              "data-test": "pinned-resources",
              device: device,
              channels: _vm.deviceChannelsMap[device["instance_id"]],
              channelsToDisplay: _vm.cardsToDisplay,
              pinned: Boolean(_vm.userPinsMap[device["instance_id"]]),
            },
            on: { togglePin: _vm.handlePinToggle },
          })
        }),
        1
      ),
      !_vm.threeLibrariesOrFewer && _vm.unpinnedDevicesExist
        ? _c(
            "KGrid",
            { staticClass: "other-libraries-grid" },
            [
              _c(
                "KGridItem",
                {
                  attrs: {
                    layout12: { span: 10 },
                    layout8: { span: 6 },
                    layout4: { span: 2 },
                  },
                },
                [
                  _vm.pinnedDevicesExist
                    ? _c(
                        "h2",
                        {
                          style: { marginTop: "0px", marginLeft: "-8px" },
                          attrs: { "data-test": "more-label" },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.injectedtr("moreLibraries")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
              _c(
                "KGridItem",
                {
                  attrs: {
                    layout12: { span: 2, alignment: "right" },
                    layout8: { span: 2, alignment: "right" },
                    layout4: { span: 2, alignment: "right" },
                  },
                },
                [
                  _c("KRouterLink", {
                    attrs: {
                      appearance: "raised-button",
                      text: _vm.explore$,
                      to: _vm.genExploreLibrariesPageBackLink(),
                    },
                  }),
                ],
                1
              ),
              _vm._l(
                _vm.unpinnedDevices.slice(0, _vm.cardsToDisplay),
                function (device) {
                  return _c(
                    "KGridItem",
                    {
                      key: device.id,
                      attrs: { layout: { span: _vm.layoutSpan } },
                    },
                    [
                      _c("UnPinnedDevices", {
                        attrs: {
                          "data-test": "more-devices",
                          device: device,
                          channelCount:
                            _vm.deviceChannelsMap[device.instance_id].length,
                          routeTo: _vm.genLibraryPageBackLink(device.id),
                        },
                      }),
                    ],
                    1
                  )
                }
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }