var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KGrid",
    {
      staticClass: "mobile-header",
      style: { backgroundColor: _vm.$themeTokens.surface },
      attrs: { "data-test": "mobile-header" },
    },
    [
      _c("KGridItem", { attrs: { layout4: { span: 3 } } }, [
        _c(
          "h1",
          {
            staticClass: "mobile-title",
            attrs: { "data-test": "mobile-title" },
          },
          [
            _c("TextTruncatorCss", {
              attrs: { text: _vm.topic.title, maxLines: 2 },
            }),
          ],
          1
        ),
      ]),
      _c("KGridItem", { attrs: { layout4: { span: 1 } } }, [
        _c("img", {
          staticClass: "channel-logo",
          attrs: { src: _vm.topic.thumbnail },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }