var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.contentNode
    ? _c(
        "div",
        { staticClass: "resource-card-outer" },
        [
          !_vm.contentNode.is_leaf
            ? _c("div", {
                staticClass: "topic-bar",
                style: { backgroundColor: _vm.$themeTokens.text },
              })
            : _vm._e(),
          _c(
            "BaseCard",
            _vm._b(
              {
                staticClass: "resource-card",
                scopedSlots: _vm._u(
                  [
                    {
                      key: "topLeft",
                      fn: function () {
                        return [
                          _c("ContentNodeThumbnail", {
                            attrs: {
                              contentNode: _vm.contentNode,
                              rounded: "",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "topRight",
                      fn: function () {
                        return [
                          _vm.contentNode.is_leaf
                            ? _c("LearningActivityLabel", {
                                attrs: { contentNode: _vm.contentNode },
                              })
                            : _c("KLabeledIcon", {
                                attrs: {
                                  iconAfter: "topic",
                                  label: _vm.coreString("folder"),
                                },
                              }),
                          _vm.contentNode.copies
                            ? _c("KButton", {
                                staticClass: "copies",
                                attrs: {
                                  appearance: "basic-link",
                                  text: _vm.coreString("copies", {
                                    num: _vm.contentNode.copies.length,
                                  }),
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.$emit(
                                      "openCopiesModal",
                                      _vm.contentNode.copies
                                    )
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "progress",
                      fn: function () {
                        return [
                          !_vm.$slots.footer
                            ? _c("ProgressBar", {
                                attrs: { contentNode: _vm.contentNode },
                              })
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2713901191
                ),
              },
              "BaseCard",
              {
                to: _vm.to,
                title: _vm.title,
                collectionTitle: _vm.collectionTitle,
              },
              false
            )
          ),
          _vm._t("footer"),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }