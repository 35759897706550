var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isFetched && !_vm.isDeviceAvailable
    ? _c(
        "span",
        [
          _c(
            "span",
            { staticClass: "inner", staticStyle: { "font-size": "14px" } },
            [_vm._v("\n    " + _vm._s(_vm.coreString("disconnected")) + "\n  ")]
          ),
          _c("KIconButton", {
            attrs: {
              icon: "disconnected",
              "data-test": "disconnected-icon",
              color: _vm.color,
              tooltip: _vm.coreString("disconnected"),
              ariaLabel: _vm.coreString("disconnected"),
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }