var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "h2",
        [
          _vm._l(_vm.topic.prefixTitles || [], function (prefixTitle) {
            return [
              _c(
                "span",
                {
                  key: prefixTitle,
                  style: { color: _vm.$themeTokens.annotation },
                },
                [_vm._v("\n        " + _vm._s(prefixTitle) + "\n      ")]
              ),
              _c("KIcon", {
                key: _vm.topic.title + prefixTitle,
                style: { top: "4px" },
                attrs: {
                  icon: "chevronRight",
                  color: _vm.$themeTokens.annotation,
                },
              }),
            ]
          }),
          _c("KRouterLink", {
            staticClass: "folder-header-link",
            attrs: {
              text: _vm.topic.title,
              to: _vm.genContentLinkKeepCurrentBackLink(_vm.topic.id, false),
              appearanceOverrides: { color: _vm.$themeTokens.text },
            },
            scopedSlots: _vm._u([
              {
                key: "iconAfter",
                fn: function () {
                  return [
                    _c("KIcon", {
                      style: { top: "4px" },
                      attrs: { icon: "chevronRight" },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        2
      ),
      _vm.topic.children && _vm.topic.children.length
        ? _c("LibraryAndChannelBrowserMainContent", {
            attrs: {
              "data-test": "children-cards-grid",
              contents: _vm.topic.children,
              gridType: _vm.gridType,
              allowDownloads: _vm.allowDownloads,
              currentCardViewStyle: "card",
              keepCurrentBackLink: true,
            },
            on: {
              toggleInfoPanel: function ($event) {
                return _vm.$emit("toggleInfoPanel", $event)
              },
            },
          })
        : _vm._e(),
      _vm.topic.showMore
        ? _c(
            "KButton",
            {
              staticClass: "more-after-grid",
              attrs: { "data-test": "more-button", appearance: "basic-link" },
              on: {
                click: function ($event) {
                  return _vm.$emit("showMore", _vm.topic.id)
                },
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.coreString("showMoreAction")) + "\n  "
              ),
            ]
          )
        : _vm.topic.viewAll
        ? _c(
            "KRouterLink",
            {
              staticClass: "more-after-grid",
              attrs: { to: _vm.topic.viewAll },
            },
            [_vm._v("\n    " + _vm._s(_vm.coreString("viewAll")) + "\n  ")]
          )
        : _vm.topic.viewMore && _vm.topic.id !== _vm.subTopicLoading
        ? _c(
            "KButton",
            {
              staticClass: "more-after-grid",
              attrs: { appearance: "basic-link" },
              on: {
                click: function ($event) {
                  return _vm.$emit("loadMoreInSubtopic", _vm.topic.id)
                },
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.coreString("viewMoreAction")) + "\n  "
              ),
            ]
          )
        : _vm._e(),
      _vm.topic.id === _vm.subTopicLoading ? _c("KCircularLoader") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }