var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayMeteredConnectionWarning
    ? _c(
        "KModal",
        {
          attrs: {
            title: _vm.$tr("modalTitle"),
            submitText: _vm.coreString("continueAction"),
            submitDisabled: _vm.loading,
          },
          on: { submit: _vm.submit },
        },
        [
          _c(
            "div",
            [
              _c("p", [_vm._v(_vm._s(_vm.$tr("modalDescription")))]),
              _c("KRadioButton", {
                staticClass: "radio-button",
                attrs: {
                  label: _vm.$tr("doNotUseMetered"),
                  buttonValue: _vm.Options.DO_NOT_USE_METERED,
                  disabled: _vm.loading,
                },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
              _c("KRadioButton", {
                staticClass: "radio-button",
                attrs: {
                  label: _vm.$tr("useMetered"),
                  buttonValue: _vm.Options.USE_METERED,
                  disabled: _vm.loading,
                },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }