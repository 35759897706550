var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CardLink", { staticClass: "base-card", attrs: { to: _vm.to } }, [
    _c(
      "div",
      [
        _vm.$slots.topLeft || _vm.$slots.topRight
          ? _c(
              "KFixedGrid",
              { style: { marginBottom: "12px" }, attrs: { numCols: "2" } },
              [
                _c(
                  "KFixedGridItem",
                  { attrs: { span: "1" } },
                  [_vm._t("topLeft")],
                  2
                ),
                _c(
                  "KFixedGridItem",
                  { attrs: { span: "1", alignment: "right" } },
                  [_vm._t("topRight")],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.collectionTitle
          ? _c(
              "div",
              {
                style: { color: _vm.$themeTokens.annotation },
                attrs: { dir: "auto", "data-test": "collectionTitle" },
              },
              [_vm._v("\n      " + _vm._s(_vm.collectionTitle) + "\n    ")]
            )
          : _vm._e(),
        _c(
          "h3",
          { staticClass: "title" },
          [
            _c("TextTruncatorCss", {
              attrs: { dir: "auto", text: _vm.title, maxLines: 1 },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "progress",
        style: { color: _vm.$themeTokens.annotation },
      },
      [
        _vm._t("progress", function () {
          return [
            _vm.inProgressLabel
              ? _c("KLabeledIcon", {
                  attrs: {
                    color: _vm.$themeTokens.progress,
                    label: _vm.inProgressLabel,
                    icon: "inProgress",
                  },
                })
              : _vm.completedLabel
              ? _c("KLabeledIcon", {
                  attrs: {
                    color: _vm.$themeTokens.mastered,
                    label: _vm.completedLabel,
                    icon: "mastered",
                  },
                })
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }