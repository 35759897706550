var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ImmersivePage",
    { attrs: { route: _vm.homePageLink, appBarTitle: _vm.exam.title || "" } },
    [
      _c(
        "KPageContainer",
        { staticClass: "container", attrs: { topMargin: 50 } },
        [
          _vm.loading
            ? _c("KCircularLoader")
            : _vm.exerciseContentNodes && _vm.exerciseContentNodes.length
            ? _c(
                "div",
                [
                  _c("ExamReport", {
                    attrs: {
                      contentId: _vm.exam.id,
                      title: _vm.exam.title,
                      userName: _vm.userName,
                      userId: _vm.userId,
                      selectedInteractionIndex: _vm.selectedInteractionIndex,
                      questionNumber: _vm.questionNumber,
                      tryIndex: _vm.tryIndex,
                      exercise: _vm.exercise,
                      exerciseContentNodes: _vm.exerciseContentNodes,
                      navigateTo: _vm.navigateTo,
                      questions: _vm.questions,
                    },
                    on: { noCompleteTries: _vm.noCompleteTries },
                  }),
                ],
                1
              )
            : _c("div", [
                _c("p", { staticClass: "no-exercise" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$tr("missingContent")) +
                      "\n      "
                  ),
                ]),
              ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }