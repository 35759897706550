var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "activity-chip",
      style: { backgroundColor: _vm.$themePalette.grey.v_100 },
    },
    [
      _c("KLabeledIcon", {
        staticClass: "activity-chip-label",
        attrs: { label: _vm.coreString(_vm.kind) },
        scopedSlots: _vm._u([
          {
            key: "icon",
            fn: function () {
              return [_c("LearningActivityIcon", { attrs: { kind: _vm.kind } })]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }