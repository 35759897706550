var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "card-main-wrapper",
      class: _vm.$computedClass({ ":focus": _vm.$coreOutline }),
      style: _vm.cardStyle,
      attrs: { to: _vm.link },
    },
    [
      _vm.explore
        ? _c("div", { staticClass: "explore" }, [
            _c("h1", [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]),
          ])
        : _c(
            "div",
            [
              _c(
                "h3",
                {
                  staticClass: "title",
                  style: {
                    borderBottom: "1px solid " + _vm.$themeTokens.fineLine,
                  },
                  attrs: { dir: "auto" },
                },
                [
                  _c("TextTruncatorCss", {
                    attrs: { text: _vm.title, maxLines: 2, showTooltip: true },
                  }),
                ],
                1
              ),
              _c(
                "KFixedGrid",
                {
                  staticStyle: { margin: "0 16px" },
                  attrs: { numCols: "4", gutter: "16" },
                },
                [
                  _c(
                    "KFixedGridItem",
                    { attrs: { span: "1" } },
                    [
                      _c(
                        "ChannelThumbnail",
                        _vm._b(
                          { staticClass: "thumbnail" },
                          "ChannelThumbnail",
                          { thumbnail: _vm.thumbnail, isMobile: _vm.isMobile },
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "KFixedGridItem",
                    { attrs: { span: "3", alignment: "auto" } },
                    [
                      _c("TextTruncatorCss", {
                        attrs: {
                          text: _vm.tagline,
                          maxLines: 4,
                          showTooltip: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isUserLoggedIn && !_vm.isLearner
                ? _c("CoachContentLabel", {
                    staticClass: "coach-content-label",
                    attrs: { value: _vm.numCoachContents, isTopic: true },
                  })
                : _vm._e(),
              _vm.version
                ? _c(
                    "div",
                    { staticClass: "version-wrapper", style: _vm.versionStyle },
                    [
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.$tr("version", { version: _vm.version }))
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.isRemote
                ? _c(
                    "div",
                    { staticClass: "wifi-icon" },
                    [_c("KIcon", { attrs: { icon: "wifi" } })],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }