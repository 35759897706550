var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LearnAppBarPage",
    { attrs: { appBarTitle: _vm.learnString("learnLabel") } },
    [
      _c(
        "div",
        { attrs: { id: "main", role: "main" } },
        [
          _c("h1", [
            _vm._v("\n      " + _vm._s(_vm.$tr("bookmarksHeader")) + "\n    "),
          ]),
          !_vm.bookmarks.length && !_vm.loading
            ? _c("p", [
                _vm._v("\n      " + _vm._s(_vm.$tr("noBookmarks")) + "\n    "),
              ])
            : _vm._l(_vm.bookmarks, function (contentNode) {
                return _c("CardList", {
                  key: contentNode.id,
                  staticClass: "card-grid-item",
                  attrs: {
                    contentNode: contentNode,
                    isMobile: _vm.windowIsSmall,
                    to: _vm.genContentLinkBackLinkCurrentPage(
                      contentNode.id,
                      contentNode.is_leaf
                    ),
                    createdDate: contentNode.bookmark
                      ? contentNode.bookmark.created
                      : null,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c("HybridLearningFooter", {
                              attrs: {
                                contentNode: contentNode,
                                bookmarked: true,
                              },
                              on: {
                                toggleInfoPanel: function ($event) {
                                  return _vm.toggleInfoPanel(contentNode)
                                },
                                removeFromBookmarks: function ($event) {
                                  return _vm.removeFromBookmarks(
                                    contentNode.bookmark
                                  )
                                },
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                })
              }),
          _vm.more && !_vm.loading
            ? _c("KButton", {
                attrs: {
                  "data-test": "load-more-button",
                  text: _vm.coreString("viewMoreAction"),
                },
                on: { click: _vm.loadMore },
              })
            : _vm.loading
            ? _c("KCircularLoader", { attrs: { delay: false } })
            : _vm._e(),
        ],
        2
      ),
      _vm.sidePanelContent
        ? _c(
            "SidePanelModal",
            {
              attrs: { alignment: "right", closeButtonIconType: "close" },
              on: {
                closePanel: function ($event) {
                  _vm.sidePanelContent = null
                },
                shouldFocusFirstEl: function ($event) {
                  return _vm.findFirstEl()
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return _vm._l(
                        _vm.sidePanelContent.learning_activities,
                        function (activity) {
                          return _c(
                            "div",
                            {
                              key: activity,
                              staticClass: "side-panel-chips",
                              class: _vm.$computedClass({
                                "::after": {
                                  content: "",
                                  flex: "auto",
                                },
                              }),
                            },
                            [
                              _c("LearningActivityChip", {
                                staticClass: "chip",
                                staticStyle: {
                                  "margin-left": "8px",
                                  "margin-bottom": "8px",
                                },
                                attrs: { kind: activity },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1103655693
              ),
            },
            [
              _c("BrowseResourceMetadata", {
                ref: "resourcePanel",
                attrs: {
                  content: _vm.sidePanelContent,
                  showLocationsInChannel: true,
                  canDownloadExternally: _vm.canDownloadExternally,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }