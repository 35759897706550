var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _vm.missingLessonResources ? _c("MissingResourceAlert") : _vm._e(),
      _vm.contentNodes.length
        ? _c(
            "div",
            {
              staticClass: "content-list",
              class: _vm.nextFolder ? "bottom-link" : "",
            },
            _vm._l(_vm.contentNodes, function (content) {
              return _c(
                "KRouterLink",
                {
                  key: content.id,
                  staticClass: "item",
                  class: [
                    _vm.windowIsSmall && "small",
                    content.id === _vm.currentResourceId &&
                      _vm.$computedClass(_vm.currentlyViewedItemStyle),
                  ],
                  style: _vm.linkStyles,
                  attrs: {
                    to: content.is_leaf
                      ? _vm.genContentLinkKeepCurrentBackLink(
                          content.id,
                          content.is_leaf
                        )
                      : _vm.genContentLinkKeepPreviousBackLink(content.id),
                  },
                },
                [
                  content.id === _vm.currentResourceId
                    ? _c("p", { style: _vm.currentlyViewingTextStyle }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$tr("currentlyViewing")) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  content.is_leaf
                    ? _c("LearningActivityIcon", {
                        staticClass: "activity-icon",
                        attrs: { kind: content.learning_activities },
                      })
                    : _c("KIcon", {
                        staticClass: "topic-icon",
                        attrs: { icon: "topic" },
                      }),
                  _c("div", { staticClass: "content-meta" }, [
                    _c(
                      "div",
                      { staticClass: "text-and-time" },
                      [
                        _c("TextTruncatorCss", {
                          staticClass: "content-title",
                          attrs: { text: content.title, maxLines: 2 },
                        }),
                        content.duration
                          ? _c("TimeDuration", {
                              staticClass: "time-duration",
                              style: {
                                color: _vm.$themeTokens.annotation,
                              },
                              attrs: { seconds: content.duration },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "progress" },
                      [
                        _vm.progressFor(content) === 1
                          ? _c("KIcon", {
                              staticClass: "mastered-icon",
                              style: { fill: _vm.$themeTokens.mastered },
                              attrs: { icon: "star" },
                            })
                          : _c("ProgressBar", {
                              staticClass: "bar",
                              attrs: { contentNode: content },
                            }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          )
        : _vm.loading
        ? _c("KCircularLoader")
        : _c("div", [_vm._v("\n    " + _vm._s(_vm.emptyMessage) + "\n  ")]),
      _vm.nextFolder
        ? _c(
            "KRouterLink",
            {
              staticClass: "next-content-link",
              style: Object.assign(
                {},
                {
                  borderTop: "1px solid " + _vm.$themeTokens.fineLine,
                  background: _vm.$themeTokens.surface,
                },
                _vm.linkStyles
              ),
              attrs: {
                to: _vm.genContentLinkKeepPreviousBackLink(_vm.nextFolder.id),
              },
            },
            [
              _c("KIcon", {
                staticClass: "folder-icon",
                attrs: { icon: "topic" },
              }),
              _c("div", { staticClass: "next-label" }, [
                _vm._v("\n      " + _vm._s(_vm.nextFolderMessage) + "\n    "),
              ]),
              _c("div", { staticClass: "next-title" }, [
                _vm._v("\n      " + _vm._s(_vm.nextFolder.title) + "\n    "),
              ]),
              _c("KIcon", {
                staticClass: "forward-icon",
                attrs: { icon: "forward" },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }