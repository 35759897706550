var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card-thumbnail-wrapper",
      class: { "mobile-thumbnail": _vm.isMobile },
      style: _vm.thumbnailBackground,
    },
    [
      !_vm.thumbnail
        ? _c("ContentIcon", {
            staticClass: "type-icon",
            attrs: { kind: "channel", color: _vm.$themeTokens.annotation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }