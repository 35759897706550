var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "card container drop-shadow",
      style: { backgroundColor: _vm.$themeTokens.surface },
    },
    [
      _c(
        "router-link",
        {
          staticClass: "card card-link",
          class: [
            _vm.isMobile ? "mobile-card" : "",
            _vm.$computedClass({ ":focus": _vm.$coreOutline }),
          ],
          style: { backgroundColor: _vm.$themeTokens.surface },
          attrs: { to: _vm.to },
        },
        [
          _c(
            "div",
            {
              staticClass: "card",
              style: { backgroundColor: _vm.$themeTokens.surface },
            },
            [
              _c(
                "KFixedGrid",
                { attrs: { numCols: "4" } },
                [
                  _c(
                    "KFixedGridItem",
                    {
                      staticClass: "thumb-area",
                      attrs: { span: _vm.isMobile ? 4 : 1 },
                    },
                    [
                      _c("CardThumbnail", {
                        attrs: {
                          contentNode: _vm.contentNode,
                          hideDuration: !_vm.windowIsLarge,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "KFixedGridItem",
                    {
                      staticClass: "text-area",
                      attrs: { span: _vm.isMobile ? 4 : 3 },
                    },
                    [
                      _c("span", { style: { color: _vm.$themeTokens.text } }, [
                        _c(
                          "div",
                          {
                            staticClass: "metadata-info",
                            style: { color: _vm.$themePalette.grey.v_800 },
                          },
                          [
                            _c("LearningActivityLabel", {
                              attrs: {
                                contentNode: _vm.contentNode,
                                hideDuration: true,
                                labelAfter: "",
                                condensed: "",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "h3",
                          { style: { marginTop: "4px", marginBottom: "4px" } },
                          [
                            _c("TextTruncatorCss", {
                              attrs: {
                                text: _vm.contentNode.title,
                                maxLines: 1,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.contentNode.description
                          ? _c(
                              "p",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "margin-top": "4px",
                                  "margin-bottom": "4px",
                                },
                              },
                              [
                                _c("TextTruncatorCss", {
                                  attrs: {
                                    text: _vm.contentNode.description,
                                    maxLines: 2,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        !_vm.isMobile
                          ? _c(
                              "div",
                              { staticClass: "bottom-items" },
                              [
                                !_vm.windowIsLarge
                                  ? _c("LearningActivityDuration", {
                                      class: _vm.categoryAndLevelString
                                        ? "duration prepends"
                                        : "duration",
                                      style: {
                                        color: _vm.$themePalette.grey.v_800,
                                        marginTop: 0,
                                      },
                                      attrs: {
                                        contentNode: _vm.contentNode,
                                        condensed: "",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.categoryAndLevelString
                                  ? _c(
                                      "p",
                                      {
                                        staticClass: "metadata-info",
                                        style: {
                                          color: _vm.$themePalette.grey.v_800,
                                          marginTop: 0,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.categoryAndLevelString)
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  [
                                    _vm.channelThumbnail
                                      ? _c("img", {
                                          staticClass: "channel-logo",
                                          style: {
                                            color: _vm.$themePalette.grey.v_800,
                                          },
                                          attrs: {
                                            src: _vm.channelThumbnail,
                                            alt: _vm.learnString("logo", {
                                              channelTitle: _vm.channelTitle,
                                            }),
                                          },
                                        })
                                      : _c(
                                          "p",
                                          {
                                            staticClass: "metadata-info",
                                            style: {
                                              color:
                                                _vm.$themePalette.grey.v_800,
                                              marginTop: 0,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.learnString("logo", {
                                                  channelTitle:
                                                    _vm.channelTitle,
                                                })
                                              )
                                            ),
                                          ]
                                        ),
                                    _vm.contentNode.copies
                                      ? _c("KButton", {
                                          staticClass: "copies",
                                          style: {
                                            color: _vm.$themeTokens.text,
                                          },
                                          attrs: {
                                            appearance: "basic-link",
                                            text: _vm.coreString("copies", {
                                              num: _vm.contentNode.copies
                                                .length,
                                            }),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.$emit(
                                                "openCopiesModal",
                                                _vm.contentNode.copies
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _vm.createdDate
                        ? _c(
                            "p",
                            {
                              staticClass: "created-info",
                              style: { color: _vm.$themePalette.grey.v_800 },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.bookmarkCreated) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }