var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: [
        "resource-label",
        _vm.condensed ? "resource-label-condensed" : {},
      ],
    },
    [
      !_vm.hideIcon
        ? [
            _vm.contentNode.is_leaf
              ? _c("LearningActivityIcon", {
                  staticClass: "icon",
                  attrs: { kind: _vm.contentNode.learning_activities },
                })
              : _c("KIcon", {
                  staticClass: "icon",
                  attrs: { icon: "topic", color: _vm.$themePalette.grey.v_600 },
                }),
          ]
        : _vm._e(),
      _c(
        "span",
        { staticClass: "text-wrapper" },
        [
          _c(
            _vm.contentNodeRoute ? "KRouterLink" : "span",
            {
              tag: "component",
              staticClass: "title",
              attrs: {
                to: _vm.contentNodeRoute,
                tabindex: _vm.disableLinkFocus ? -1 : 0,
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.contentNode.title) + "\n    ")]
          ),
          _vm.contentNode.duration
            ? _c("TimeDuration", {
                staticClass: "duration",
                style: { color: _vm.$themePalette.grey.v_800 },
                attrs: { seconds: _vm.contentNode.duration },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }