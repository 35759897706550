var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.lesson
    ? _c(
        "BaseCard",
        _vm._b(
          {},
          "BaseCard",
          {
            to: _vm.to,
            title: _vm.title,
            collectionTitle: _vm.collectionTitle,
            completedLabel: _vm.completedLabel,
            inProgressLabel: _vm.inProgressLabel,
          },
          false
        )
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }