var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal-fade", appear: "" } }, [
    _c(
      "div",
      {
        staticClass: "modal-overlay",
        on: {
          keyup: [
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              $event.stopPropagation()
              return _vm.emitCloseEvent.apply(null, arguments)
            },
            function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.goToNextContentNode.apply(null, arguments)
            },
          ],
        },
      },
      [
        _c(
          "div",
          {
            ref: "modal",
            staticClass: "modal",
            style: [
              _vm.modalSizeStyles,
              { background: _vm.$themeTokens.surface },
            ],
            attrs: {
              tabindex: 0,
              role: "dialog",
              "aria-labelledby": "modal-title",
            },
          },
          [
            _c(
              "FocusTrap",
              {
                on: {
                  shouldFocusFirstEl: function ($event) {
                    return _vm.$emit("shouldFocusFirstEl")
                  },
                  shouldFocusLastEl: _vm.focusLastEl,
                },
              },
              [
                _c(
                  "KFixedGrid",
                  { style: { margin: "24px" }, attrs: { numCols: 12 } },
                  [
                    _c("KFixedGridItem", { attrs: { span: 9 } }, [
                      _c(
                        "h1",
                        { staticClass: "title", attrs: { id: "modal-title" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.learnString("resourceCompletedLabel")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                    _c("KFixedGridItem", {
                      attrs: { span: 3, alignment: "right" },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { style: _vm.contentStyle },
                  [
                    !_vm.isUserLoggedIn
                      ? _c(
                          "UiAlert",
                          {
                            style: { marginTop: "8px" },
                            attrs: {
                              dismissible: false,
                              removeIcon: true,
                              type: "warning",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$tr("signIn")) +
                                "\n          "
                            ),
                          ]
                        )
                      : !_vm.wasComplete
                      ? _c("div", { staticClass: "stats" }, [
                          _c(
                            "div",
                            { staticClass: "points" },
                            [
                              _c(
                                "span",
                                { style: { color: _vm.$themeTokens.correct } },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$tr("plusPoints", {
                                          points: _vm.points,
                                        })
                                      ) +
                                      "\n              "
                                  ),
                                ]
                              ),
                              _c("KIcon", {
                                style: { display: "inline-block" },
                                attrs: {
                                  icon: "pointsActive",
                                  color: _vm.$themeTokens.primary,
                                },
                              }),
                            ],
                            1
                          ),
                          _c("div", [
                            _vm._v(_vm._s(_vm.$tr("keepUpTheGreatProgress"))),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.loading
                      ? _c("KCircularLoader", { staticClass: "loader" })
                      : [
                          _vm.nextContentNode
                            ? _c(
                                "CompletionModalSection",
                                {
                                  ref: "nextContentNodeSection",
                                  class: _vm.sectionClass,
                                  attrs: {
                                    icon: "forwardRounded",
                                    title: _vm.$tr("moveOnTitle"),
                                    description: _vm.$tr("moveOnDescription"),
                                    buttonLabel: _vm.$tr("moveOnButtonLabel"),
                                    buttonRoute: _vm.nextContentNodeRoute,
                                  },
                                },
                                [
                                  _c("ResourceItem", {
                                    attrs: {
                                      contentNode: _vm.nextContentNode,
                                      size: "small",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("CompletionModalSection", {
                            ref: "staySection",
                            class: _vm.sectionClass,
                            attrs: {
                              icon:
                                _vm.isQuiz || _vm.isSurvey
                                  ? "reports"
                                  : "restart",
                              title: _vm.staySectionTitle,
                              description: _vm.staySectionDescription,
                              buttonLabel:
                                _vm.isQuiz || _vm.isSurvey
                                  ? _vm.$tr("reviewQuizButtonLabel")
                                  : _vm.$tr("stayButtonLabel"),
                            },
                            on: {
                              buttonClick: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          }),
                          _vm.recommendedContentNodes &&
                          _vm.recommendedContentNodes.length
                            ? _c(
                                "CompletionModalSection",
                                {
                                  class: _vm.sectionClass,
                                  attrs: {
                                    icon: "alternativeRoute",
                                    title: _vm.$tr("helpfulResourcesTitle"),
                                    description: _vm.$tr(
                                      "helpfulResourcesDescription"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "KGrid",
                                    { style: { marginTop: "6px" } },
                                    _vm._l(
                                      _vm.recommendedContentNodes,
                                      function (node) {
                                        return _c(
                                          "KGridItem",
                                          {
                                            key: node.id,
                                            style: { marginBottom: "24px" },
                                            attrs: {
                                              layout12: { span: 6 },
                                              layout8: { span: 4 },
                                              layout4: { span: 4 },
                                            },
                                          },
                                          [
                                            _c("ResourceItem", {
                                              attrs: {
                                                "data-test":
                                                  "recommended-resource",
                                                contentNode: node,
                                                contentNodeRoute:
                                                  _vm.genContentLinkKeepCurrentBackLink(
                                                    node.id,
                                                    node.is_leaf
                                                  ),
                                                size: _vm.recommendedResourceItemSize,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                  ],
                  2
                ),
                _c("KIconButton", {
                  ref: "closeButton",
                  staticClass: "close-button",
                  attrs: {
                    icon: "close",
                    ariaLabel: _vm.coreString("closeAction"),
                    tooltip: _vm.coreString("closeAction"),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }