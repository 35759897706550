var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card drop-shadow" },
    [
      _c(
        "router-link",
        {
          staticClass: "card card-link",
          class: [_vm.$computedClass({ ":focus": _vm.$coreOutline })],
          style: { backgroundColor: _vm.$themeTokens.surface },
          attrs: { to: _vm.to },
        },
        [
          _c(
            "div",
            { staticClass: "header-bar", style: _vm.headerStyles },
            [
              !_vm.contentNode.is_leaf
                ? _c(
                    "div",
                    [
                      _c("KIcon", {
                        staticClass: "folder-header-bar",
                        attrs: {
                          icon: "topic",
                          color: _vm.$themeTokens.textInverted,
                        },
                      }),
                      _c("p", { staticClass: "folder-header-text" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.coreString("folder")) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.contentNode.is_leaf
                ? _c("LearningActivityLabel", {
                    staticClass: "learning-activity-label",
                    style: { color: _vm.$themeTokens.text },
                    attrs: {
                      labelAfter: true,
                      contentNode: _vm.contentNode,
                      hideDuration: true,
                    },
                  })
                : _vm._e(),
              _vm.contentNode.is_leaf && _vm.channelThumbnail.length > 0
                ? _c("img", {
                    staticClass: "channel-logo",
                    attrs: {
                      src: _vm.channelThumbnail,
                      alt: _vm.learnString("logo", {
                        channelTitle: _vm.channelTitle,
                      }),
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("CardThumbnail", {
            staticClass: "thumbnail",
            attrs: { contentNode: _vm.contentNode },
          }),
          _c(
            "div",
            { staticClass: "text", style: { color: _vm.$themeTokens.text } },
            [
              _c(
                "h3",
                { staticClass: "title", attrs: { dir: "auto" } },
                [
                  _c("TextTruncatorCss", {
                    attrs: { text: _vm.contentNode.title, maxLines: 1 },
                  }),
                ],
                1
              ),
              _vm.contentNode.copies && _vm.contentNode.copies.length
                ? _c("KButton", {
                    staticClass: "copies",
                    attrs: {
                      appearance: "basic-link",
                      text: _vm.coreString("copies", {
                        num: _vm.contentNode.copies.length,
                      }),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$emit(
                          "openCopiesModal",
                          _vm.contentNode.copies
                        )
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }