var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sidePanel",
      class: { "is-rtl": _vm.isRtl, "is-mobile": _vm.isMobile },
      attrs: { tabindex: 0 },
      on: {
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closePanel.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "side-panel" } },
        [
          _c(
            "FocusTrap",
            {
              on: {
                shouldFocusFirstEl: function ($event) {
                  return _vm.$emit("shouldFocusFirstEl")
                },
                shouldFocusLastEl: _vm.focusLastEl,
              },
            },
            [
              _c(
                "section",
                {
                  staticClass: "side-panel",
                  style: _vm.sidePanelStyles,
                  attrs: { role: "region", "aria-label": _vm.ariaLabel },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$slots.header,
                          expression: "$slots.header",
                        },
                      ],
                      ref: "fixedHeader",
                      staticClass: "fixed-header",
                      style: _vm.fixedHeaderStyles,
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "header-content" },
                        [_vm._t("header")],
                        2
                      ),
                    ]
                  ),
                  _vm.closeButtonIconType
                    ? _c("KIconButton", {
                        staticClass: "close-button",
                        style: _vm.closeButtonStyle,
                        attrs: {
                          icon: _vm.closeButtonIconType,
                          ariaLabel: _vm.coreString("closeAction"),
                          tooltip: _vm.coreString("closeAction"),
                        },
                        on: { click: _vm.closePanel },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass: "side-panel-content",
                      style: _vm.contentStyles,
                    },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("Backdrop", {
        staticClass: "backdrop",
        attrs: { transitions: true },
        on: { click: _vm.closePanel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }