var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "mainWrapper", staticClass: "main-wrapper" },
    [
      _c("SkipNavigationLink"),
      _c("LearningActivityBar", {
        ref: "activityBar",
        attrs: {
          resourceTitle: _vm.resourceTitle,
          learningActivities: _vm.contentLearningActivities,
          isLessonContext: _vm.lessonContext,
          isQuiz: _vm.practiceQuiz,
          showingReportState: _vm.contentProgress >= 1,
          duration: _vm.contentDuration,
          timeSpent: _vm.timeSpent,
          isBookmarked: _vm.bookmark ? true : _vm.bookmark,
          isCoachContent: _vm.isCoachContent,
          contentProgress: _vm.contentProgress,
          allowMarkComplete: _vm.allowMarkComplete,
          contentKind: _vm.contentKind,
          showBookmark: _vm.allowBookmark,
          showDownloadButton: _vm.showDownloadButton,
          isDownloading: _vm.isDownloading,
          downloadingLoaderTooltip: _vm.downloadRequestsTranslator.$tr(
            "downloadStartedLabel"
          ),
          "data-test": "learningActivityBar",
        },
        on: {
          navigateBack: _vm.navigateBack,
          toggleBookmark: _vm.toggleBookmark,
          download: _vm.handleRemoteDownloadRequest,
          viewResourceList: _vm.toggleResourceList,
          viewInfo: _vm.openSidePanel,
          completionModal: _vm.openCompletionModal,
        },
      }),
      _vm.loading
        ? _c("KLinearLoader", {
            staticClass: "loader",
            attrs: { type: "indeterminate", delay: false },
          })
        : _vm._e(),
      _vm.notAuthorized
        ? _c(
            "KPageContainer",
            [
              _c("AuthMessage", {
                attrs: {
                  authorizedRole: _vm.authorizedRole,
                  header: _vm.authorizationErrorHeader,
                  details: _vm.authorizationErrorDetails,
                },
              }),
            ],
            1
          )
        : _vm.error
        ? _c("KPageContainer", [_c("AppError")], 1)
        : !_vm.loading && _vm.content
        ? _c(
            "div",
            {
              staticClass: "main",
              attrs: { id: "main", role: "main", tabindex: "-1" },
            },
            [
              _c("ContentPage", {
                ref: "contentPage",
                staticClass: "content",
                style: {
                  backgroundColor: _vm.content.assessmentmetadata
                    ? ""
                    : _vm.$themeTokens.textInverted,
                },
                attrs: {
                  "data-test": "contentPage",
                  content: _vm.content,
                  lessonId: _vm.lessonId,
                  allowMarkComplete: _vm.allowMarkComplete,
                },
                on: {
                  mounted: function ($event) {
                    _vm.contentPageMounted = true
                  },
                  finished: function ($event) {
                    _vm.$refs.activityBar &&
                      _vm.$refs.activityBar.animateNextSteps()
                  },
                  error: _vm.onError,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("GlobalSnackbar"),
      _vm.sidePanelContent
        ? _c(
            "SidePanelModal",
            {
              ref: "resourcePanel",
              attrs: { alignment: "right", closeButtonIconType: "close" },
              on: {
                closePanel: function ($event) {
                  _vm.sidePanelContent = null
                },
                shouldFocusFirstEl: function ($event) {
                  return _vm.findFirstEl()
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return _vm._l(
                        _vm.sidePanelContent.learning_activities,
                        function (activity) {
                          return _c(
                            "div",
                            {
                              key: activity,
                              staticClass: "side-panel-chips",
                              class: _vm.$computedClass({
                                "::after": {
                                  content: "",
                                  flex: "auto",
                                },
                              }),
                            },
                            [
                              _c("LearningActivityChip", {
                                staticClass: "chip",
                                staticStyle: {
                                  "margin-left": "8px",
                                  "margin-bottom": "8px",
                                },
                                attrs: { kind: activity },
                              }),
                            ],
                            1
                          )
                        }
                      )
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1103655693
              ),
            },
            [
              _c("CurrentlyViewedResourceMetadata", {
                attrs: {
                  content: _vm.sidePanelContent,
                  canDownloadExternally: _vm.canDownloadExternally,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showViewResourcesSidePanel
        ? _c(
            "SidePanelModal",
            {
              ref: "resourcePanel",
              staticClass: "also-in-this-side-panel",
              attrs: { alignment: "right", closeButtonIconType: "close" },
              on: {
                closePanel: function ($event) {
                  _vm.showViewResourcesSidePanel = false
                },
                shouldFocusFirstEl: function ($event) {
                  return _vm.findFirstEl()
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "header",
                    fn: function () {
                      return [
                        _c("h2", { staticStyle: { margin: "0" } }, [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.viewResourcesTitle) +
                              "\n      "
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2791589371
              ),
            },
            [
              _c("AlsoInThis", {
                staticStyle: { "margin-top": "8px" },
                attrs: {
                  contentNodes: _vm.viewResourcesContents,
                  nextFolder: _vm.nextFolder,
                  isLesson: _vm.lessonContext,
                  loading: _vm.resourcesSidePanelLoading,
                  currentResourceId: _vm.currentResourceId,
                  missingLessonResources: _vm.missingLessonResources,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showConnectionErrorModal
        ? _c(
            "KModal",
            {
              attrs: {
                title: _vm.deviceFormTranslator.$tr("errorCouldNotConnect"),
                submitText: _vm.coreString("goBackAction"),
              },
              on: { submit: _vm.goToAllLibraries },
            },
            [
              _c("p", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.learnString("cannotConnectToLibrary", {
                        deviceName: _vm.deviceName,
                      })
                    ) +
                    "\n    "
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }