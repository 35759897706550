var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "search-box",
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updateSearchQuery.apply(null, arguments)
        },
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          $event.preventDefault()
          return _vm.handleEscKey.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "search-box-row",
          style: {
            backgroundColor: _vm.$themeTokens.surface,
            borderColor: _vm.$themePalette.grey.v_400,
            fontSize: "16px",
          },
        },
        [
          _c(
            "label",
            { staticClass: "visuallyhidden", attrs: { for: "searchfield" } },
            [_vm._v(_vm._s(_vm.coreString("searchLabel")))]
          ),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.newSearchTerm,
                expression: "newSearchTerm",
                modifiers: { trim: true },
              },
            ],
            ref: "searchInput",
            staticClass: "search-input",
            class: _vm.$computedClass(_vm.searchInputStyle),
            attrs: {
              id: _vm.id,
              type: "search",
              dir: "auto",
              placeholder: _vm.coreString(_vm.placeholder),
            },
            domProps: { value: _vm.newSearchTerm },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newSearchTerm = $event.target.value.trim()
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
          _c(
            "div",
            { staticClass: "search-buttons-wrapper" },
            [
              _c("KIconButton", {
                staticClass: "search-clear-button",
                class:
                  _vm.searchInputValue === ""
                    ? ""
                    : "search-clear-button-visible",
                attrs: {
                  icon: "clear",
                  color: _vm.$themeTokens.text,
                  size: "small",
                  ariaLabel: _vm.coreString("clearAction"),
                },
                on: { click: _vm.handleClickClear },
              }),
              _c("KButton", {
                attrs: {
                  disabled: _vm.searchBarDisabled,
                  primary: true,
                  appearanceOverrides: { minWidth: "36px", padding: 0 },
                  "aria-label": _vm.coreString("startSearchButtonLabel"),
                  type: "submit",
                },
                scopedSlots: _vm._u([
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("KIcon", {
                          style: { width: "24px", height: "24px", top: "7px" },
                          attrs: {
                            icon: _vm.icon,
                            color: _vm.$themeTokens.textInverted,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }