var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ContentNodeThumbnail", {
    attrs: { contentNode: _vm.contentNode },
    scopedSlots: _vm._u([
      {
        key: "labels",
        fn: function () {
          return [
            _vm.displayDurationChip
              ? _c("LearningActivityDuration", {
                  staticClass: "duration",
                  attrs: { contentNode: _vm.contentNode, appearance: "chip" },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }