var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.channel
    ? _c(
        "CardLink",
        { staticClass: "base-channel-card", attrs: { to: _vm.to } },
        [
          _c(
            "h3",
            { staticClass: "title" },
            [
              _c("TextTruncatorCss", {
                attrs: { dir: "auto", text: _vm.channel.name, maxLines: 1 },
              }),
            ],
            1
          ),
          _c(
            "KFixedGrid",
            {
              staticClass: "content",
              style: { borderTop: "1px solid " + _vm.$themeTokens.fineLine },
              attrs: { numCols: "4", gutter: "16" },
            },
            [
              _c(
                "KFixedGridItem",
                { attrs: { span: "1" } },
                [
                  _vm.channel.thumbnail
                    ? _c("ChannelThumbnail", {
                        staticClass: "channel-thumbnail-wrapper",
                        attrs: { thumbnailUrl: _vm.channel.thumbnail },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "KFixedGridItem",
                { style: { textAlign: "unset" }, attrs: { span: "3" } },
                [
                  _vm.description
                    ? _c("TextTruncatorCss", {
                        attrs: {
                          dir: "auto",
                          text: _vm.description,
                          maxLines: 6,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }