var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "exercise-attempts" },
    [
      _vm._l(_vm.itemsToRender, function (item, index) {
        return _c(
          "div",
          {
            key: "attempt-" + item.originalIndex,
            staticClass: "attempt",
            style: _vm.styleForIndex(index, item.originalIndex),
          },
          [
            _c(
              "transition",
              { attrs: { name: "fade" } },
              [_c("AnswerIcon", { attrs: { answer: item.answer } })],
              1
            ),
          ],
          1
        )
      }),
      _vm._l(_vm.numSpaces, function (i) {
        return _c("div", {
          key: "placeholder-" + i,
          staticClass: "placeholder",
          style: { borderBottom: "2px solid " + _vm.$themeTokens.annotation },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }