var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { ref: "icon" },
        [
          _vm.answer === "right"
            ? _c("KIcon", {
                staticClass: "correct",
                staticStyle: { top: "0", width: "24px", height: "24px" },
                attrs: { icon: "correct", color: _vm.$themeTokens.correct },
              })
            : _vm.answer === "wrong"
            ? _c("KIcon", {
                staticStyle: { top: "0", width: "24px", height: "24px" },
                attrs: {
                  icon: "incorrect",
                  color: _vm.$themeTokens.annotation,
                },
              })
            : _vm.answer === "hint"
            ? _c("KIcon", {
                staticStyle: { top: "0", width: "24px", height: "24px" },
                attrs: { icon: "hint", color: _vm.$themeTokens.annotation },
              })
            : _vm.answer === "rectified"
            ? _c("KIcon", {
                staticClass: "rectified",
                attrs: {
                  icon: "rectified",
                  color: _vm.$themeTokens.annotation,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "KTooltip",
        { attrs: { reference: "icon", refs: _vm.$refs, placement: "right" } },
        [_vm._v("\n    " + _vm._s(_vm.tooltipText) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }